import { SelectTimeSelection } from "@/components/SelectTimeSelection";
import { ShowEmptyData } from "@/components/ShowEmptyData";
import { BasicTable } from "@/components/BasicTable";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { TableParser } from "@/utils/TableParser";
import { Stack } from "@mui/material";
import { SpeedInfo } from "../MachineDetail/components/SpeedInfo";
import { useGetAdjustmentsData, Adjustment } from "./api/useGetAdjustmentsData";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { useFormatTimestamp } from "@/utils/TimeHelpers";
import { useMachineContext } from "@/context/machine-context";

export const Adjustments = () => {
  const { data: adjustments } = useGetAdjustmentsData();
  const { machine } = useMachineContext();

  return (
    <>
      <MachineViewNavBar>
        <SelectTimeSelection />
      </MachineViewNavBar>
      <Stack gap={2}>
        {adjustments && <AdjustmentsTable adjustments={adjustments} />}
        {machine && machine.machineType === "cyclic" && <SpeedInfo />}
      </Stack>
    </>
  );
};

function AdjustmentsTable({ adjustments }: { adjustments: Adjustment[] }) {
  const translate = useTranslate();
  const { formatTimestamp } = useFormatTimestamp();

  if (adjustments.length === 0) {
    return <ShowEmptyData title={translate("adjustments")} />;
  }

  const { columns, rows } = TableParser.parseData<Adjustment>({
    data: adjustments,
    columnsMap: {
      NewValue: translate("adjustments.new_value"),
      OldValue: translate("adjustments.old_value"),
      PropertyName: translate("adjustments.property_name"),
      Timestamp: translate("date"),
    },
    columnsOrder: ["Timestamp", "PropertyName", "OldValue", "NewValue"],
    dateFormatter: formatTimestamp,
  });

  return (
    <Card>
      <BasicTable columns={columns} rows={rows} fileName="adjustments_data" />
    </Card>
  );
}
