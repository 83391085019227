import { useEffect, useCallback, useState, useRef } from "react";
import { toast } from "react-hot-toast";
import { DateSelectArg, EventClickArg, EventDropArg, EventInput } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { DateClickArg, EventResizeDoneArg } from "@fullcalendar/interaction";
import allLocales from "@fullcalendar/core/locales-all";
import "./Calendar.styles.css";
import { createEventsFromDbData, getFixedOffsetFromIANA } from "./utils/createEventsFromDbData";
import { Box, Button, Dialog, IconButton, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Card } from "@/components/Layout/Card";
import { ShiftsConfiguration } from "../../types";
import { Edit, InfoOutlined } from "@mui/icons-material";
import { useLanguage, useTranslate } from "@/i18n/config";
import { RenderEventContent } from "./RenderEventContent";
import { useIsAllowed } from "@/hooks/useIsAllowed";
import { UserRole } from "@/context/firebase-context";
import { timezoneStore } from "@/store/useTimezoneStore";
import { useFormatTimestamp } from "@/utils/TimeHelpers";
import { VerboseFormattingArg } from "@fullcalendar/core/internal";
import { dateTimeFormatStore } from "@/store/useDateTimeFormat";
import { WeekStartsOnSelect } from "../WeekStartsOnSelect";
import { useSetWeekAsDefault } from "../../api/useSetWeekAsDefault";
import { useEditShiftsConfiguration } from "../../api/useEditShiftsConfiguration";
import { ShiftConfiguratorGuide } from "../ShiftConfiguratorGuideModal";
import { HeaderWithGauge } from "./HeaderWithGauge";
import { DateTime } from "luxon";
import { validateOverlaps } from "./utils/parsing-conf-utils";

const MAX_SHIFTS_PER_DAY = 4;
const MAX_SHIFT_DURATION = 12 * 60 * 60 * 1000;

export interface DateConfig {
  calendar_date: string;
  is_locked: boolean;
  [key: string]: string | number | boolean;
}

function convertEventToTimeZone(event: EventInput, timezone: string): EventInput {
  if (!event.start) {
    throw new Error("L'evento non ha una data di inizio valida.");
  }

  const parseEventOffset = (offsetString: string | undefined): number => {
    if (!offsetString) return 0; // se non esiste, niente shift
    // eslint-disable-next-line no-useless-escape
    const match = offsetString.match(/^UTC([+\-]\d+)$/);
    if (!match) return 0;
    return parseInt(match[1], 10);
  };

  const offsetVal = parseEventOffset(event.eventOffset);

  const getFixedOffset = (dateString: string, timezone: string): string => {
    const originalDate = DateTime.fromISO(dateString);
    if (!originalDate.isValid) {
      throw new Error("Data non valida");
    }

    const dtInNewZone = DateTime.fromMillis(originalDate.toMillis(), {
      zone: timezone,
    });

    if (!dtInNewZone.isValid) {
      throw new Error("Conversione timezone non valida");
    }

    const offsetMinutes = dtInNewZone.offset;
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetMinutesRemaining = Math.abs(offsetMinutes % 60);
    const sign = offsetMinutes >= 0 ? "+" : "-";
    return `${sign}${String(offsetHours).padStart(2, "0")}:${String(
      offsetMinutesRemaining,
    ).padStart(2, "0")}`;
  };

  const replaceOffset = (dateString: string, newOffset: string): string => {
    const dateWithoutOffset = dateString
      .replace(/\.000Z$/, "")
      .replace(/Z$/, "")
      .replace(/[+-]\d{2}:?\d{2}$/, "");

    return `${dateWithoutOffset}${newOffset}`;
  };

  try {
    const startString =
      typeof event.start === "string"
        ? event.start
        : event.start instanceof Date
        ? event.start.toISOString()
        : String(event.start);

    const endString = event.end
      ? typeof event.end === "string"
        ? event.end
        : event.end instanceof Date
        ? event.end.toISOString()
        : String(event.end)
      : undefined;

    const startOffset = getFixedOffset(startString, timezone);
    const endOffset = endString ? getFixedOffset(endString, timezone) : startOffset;

    let firstFormattedStart = replaceOffset(startString, startOffset);
    let firstFormattedEnd = endString ? replaceOffset(endString, endOffset) : undefined;

    // **Applichiamo offsetVal spostando l'orario**
    const adjustTimeByOffset = (dateStr: string, offset: number): string => {
      const dt = DateTime.fromISO(dateStr, { setZone: true });
      const adjustedDt = dt.minus({ hours: offset });
      return adjustedDt.toFormat("yyyy-MM-dd'T'HH:mm:ss.SSSZZ");
    };

    firstFormattedStart = adjustTimeByOffset(firstFormattedStart, offsetVal);
    if (firstFormattedEnd) {
      firstFormattedEnd = adjustTimeByOffset(firstFormattedEnd, offsetVal);
    }

    return {
      ...event,
      start: firstFormattedStart,
      end: firstFormattedEnd,
    };
  } catch (error) {
    console.error("Errore nella conversione:", error);
    return event;
  }
}

export function Calendar({
  data,
  weekStartsOn,
}: {
  data: ShiftsConfiguration[];
  weekStartsOn: number;
}) {
  const { timezone } = timezoneStore();
  const { formatTimestamp } = useFormatTimestamp();
  const { timeFormat, dateFormat } = dateTimeFormatStore();
  const { mutate: setWeekAsDefault } = useSetWeekAsDefault();
  const { mutate: editShiftsConfiguration, isLoading } = useEditShiftsConfiguration();
  const translate = useTranslate();
  const language = useLanguage();
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentShifts, setCurrentShifts] = useState<EventInput[]>([]);
  const [tempShifts, setTempShifts] = useState<EventInput[]>([]);
  const [eventsLoaded, setEventsLoaded] = useState(false);
  const [showCopyWeekModal, setShowCopyWeekModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [currentView, setCurrentView] = useState<"timeGridWeek" | "dayGridMonth">("timeGridWeek");
  const [lockedDays, setLockedDays] = useState<Set<string>>(new Set());
  const [showExtraDay, setShowExtraDay] = useState(false);
  const calendarRef = useRef<FullCalendar | null>(null);
  const allowEdit = useIsAllowed([
    UserRole.USER_ADMIN,
    UserRole.SUPER_USER,
    UserRole.SUPER_USER_SENTINEL,
  ]);

  useEffect(() => {
    if (!eventsLoaded) {
      const initialShifts = createEventsFromDbData({ data, translate, timezone });
      setCurrentShifts(initialShifts);
      setTempShifts(initialShifts);

      // Inizializza i giorni bloccati dai dati esistenti
      const initialLockedDays = new Set(
        initialShifts
          .filter((shift) => shift.is_locked)
          .map((shift) => {
            const date = new Date(shift.start as string);
            return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(
              date.getDate(),
            ).padStart(2, "0")}`;
          }),
      );
      setLockedDays(initialLockedDays);
      setEventsLoaded(true);
    }
  }, [data, timezone, eventsLoaded, timeFormat, translate]);

  useEffect(() => {
    const updatedShifts = createEventsFromDbData({ data, translate, timezone });
    setCurrentShifts(updatedShifts);
    setTempShifts(updatedShifts);
    setIsEditMode(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, timezone, timeFormat]);

  const isInPast = useCallback((date: Date | null) => {
    if (!date) return true;
    const now = new Date();
    return date <= now;
  }, []);

  const formatRange = (range: VerboseFormattingArg) => {
    if (!range.start || !range.end) {
      return "";
    }

    const startDate = range.start.marker;
    const endDate = new Date(range.end.marker);
    endDate.setDate(endDate.getDate() - 1);

    const formattedStart = formatTimestamp({
      timestamp: +startDate,
      fmt: dateFormat === "en-GB" ? "dd LLL yyyy" : "MMM dd, yyyy",
      skipTimezoneTransform: true,
    });

    const formattedEnd = formatTimestamp({
      timestamp: +endDate,
      fmt: dateFormat === "en-GB" ? "dd LLL yyyy" : "MMM dd, yyyy",
      skipTimezoneTransform: true,
    });

    return `${formattedStart} – ${formattedEnd}`;
  };

  const handleEditMode = () => {
    setTempShifts([...currentShifts]);
    setIsEditMode(true);
  };

  const getEventDay = (shift: EventInput): string => {
    return new Date(shift.start as string).toDateString();
  };

  const reorganizeShifts = useCallback(
    (shifts: EventInput[]): EventInput[] => {
      // Get all unique days that have shifts
      const shiftsByDay = new Map<string, EventInput[]>();

      shifts.forEach((shift) => {
        const shiftDate = new Date(shift.start as string);
        const dateKey = shiftDate.toISOString().split("T")[0];

        if (!shiftsByDay.has(dateKey)) {
          shiftsByDay.set(dateKey, []);
        }
        shiftsByDay.get(dateKey)?.push(shift);
      });

      // Process each day's shifts
      const reorganizedShifts: EventInput[] = [];
      shiftsByDay.forEach((dayShifts) => {
        // Sort shifts by start time
        const sortedShifts = dayShifts.sort((a, b) => {
          const aTime = new Date(a.start as string).getTime();
          const bTime = new Date(b.start as string).getTime();
          return aTime - bTime;
        });

        // Reassign shift numbers based on temporal order
        sortedShifts.forEach((shift, index) => {
          reorganizedShifts.push({
            ...shift,
            title: `${translate("time_selection.Shift")} ${index + 1}`,
          });
        });
      });

      return reorganizedShifts;
    },
    [translate],
  );

  const handleEventDrop = useCallback(
    (arg: EventDropArg) => {
      const { event } = arg;
      const start = event.start!;
      const end = event.end!;

      if (isInPast(start)) {
        arg.revert();
        toast.error(translate("shifts_configuration.toast.cannot_modify_past"));
        return;
      }

      // Update shifts and reorganize based on new times
      setTempShifts((prev) => {
        const updatedShifts = prev.map((shift) =>
          shift.id === event.id
            ? {
                ...shift,
                start: start.toISOString(),
                end: end.toISOString(),
                eventOffset: getFixedOffsetFromIANA(timezone),
              }
            : shift,
        );
        return reorganizeShifts(updatedShifts);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isInPast, reorganizeShifts, tempShifts, translate],
  );

  const handleEventResize = useCallback(
    (arg: EventResizeDoneArg) => {
      const { event } = arg;
      const start = event.start!;
      const end = event.end!;
      const now = new Date();

      if (isInPast(start) || (start < now && now < end)) {
        toast.error(translate("shifts_configuration.toast.cannot_modify_past"));
        return;
      }

      const duration = end.getTime() - start.getTime();
      if (duration > MAX_SHIFT_DURATION) {
        const newEnd = new Date(start.getTime() + MAX_SHIFT_DURATION);
        event.setEnd(newEnd);
        toast.error(translate("shifts_configuration.toast.max_duration"));
        return;
      }

      setTempShifts((prev) =>
        reorganizeShifts(
          prev.map((shift) =>
            shift.id === event.id
              ? {
                  ...shift,
                  start: start.toISOString(),
                  end: end.toISOString(),
                  eventOffset: getFixedOffsetFromIANA(timezone),
                }
              : shift,
          ),
        ),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isInPast, reorganizeShifts, tempShifts],
  );

  const handleSelect = (selectInfo: DateSelectArg) => {
    if (isInPast(selectInfo.start)) {
      toast.error(translate("shifts_configuration.toast.cannot_create_past"));
      return;
    }

    // Get existing shifts for the selected day
    const existingShifts = tempShifts.filter(
      (shift) => getEventDay(shift) === selectInfo.start.toDateString(),
    );

    if (existingShifts.length >= MAX_SHIFTS_PER_DAY) {
      toast.error(translate("shifts_configuration.toast.max_shifts"));
      return;
    }

    const duration = selectInfo.end.getTime() - selectInfo.start.getTime();
    let end = selectInfo.end;

    if (duration > MAX_SHIFT_DURATION) {
      end = new Date(selectInfo.start.getTime() + MAX_SHIFT_DURATION);
      toast.error(translate("shifts_configuration.toast.max_duration"));
    }

    // Converti le date in UTC mantenendo l'ora locale
    const startDt = DateTime.fromJSDate(selectInfo.start, { zone: timezone });
    const endDt = DateTime.fromJSDate(end, { zone: timezone });

    const startUtc = new Date(startDt.toString());
    const endUtc = new Date(endDt.toString());

    const newEvent: EventInput = {
      id: `temp-${Date.now()}`,
      title: `${translate("time_selection.Shift")} ${existingShifts.length + 1}`,
      start: startUtc,
      end: endUtc,
      eventOffset: getFixedOffsetFromIANA(timezone),
      editable: true,
      isCreated: true,
    };

    setTempShifts((prev) => {
      const updatedShifts = [...prev, newEvent];
      return reorganizeShifts(updatedShifts);
    });
  };

  const handleDelete = useCallback(
    (eventId: string) => {
      setTempShifts((prev) => {
        const eventToDelete = prev.find((event) => event.id === eventId);
        if (!eventToDelete) return prev;

        // Rimuovi immediatamente l'evento
        const updatedShifts = prev.filter((event) => event.id !== eventId);

        // Forza un aggiornamento dello stato per assicurarsi che l'evento sia completamente rimosso
        setTimeout(() => {
          setTempShifts((current) => [...current]);
        }, 0);

        return reorganizeShifts(updatedShifts);
      });
      toast.success(translate("shifts_configuration.toast.shift_deleted"));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reorganizeShifts],
  );

  const handleSave = () => {
    const calendarApi = calendarRef.current?.getApi();
    if (!calendarApi) return;

    const viewStart = calendarApi.view.currentStart;
    const viewEnd = calendarApi.view.currentEnd;

    // Create a map of all dates in the current view
    const allDates = new Map<string, DateConfig>();
    const currentDate = new Date(viewStart);

    while (currentDate < viewEnd) {
      const dateKey = currentDate.toISOString().split("T")[0];
      allDates.set(dateKey, {
        calendar_date: dateKey,
        is_locked: lockedDays.has(dateKey),
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }

    // Group shifts by date and ensure proper numbering
    const shiftsByDate = new Map<string, EventInput[]>();
    tempShifts.forEach((shift: EventInput) => {
      // Handle both Date and string types for shift.start
      const startDate =
        typeof shift.start === "string" ? shift.start : (shift.start as Date).toISOString();

      const dateKey = startDate.split("T")[0];
      if (!shiftsByDate.has(dateKey)) {
        shiftsByDate.set(dateKey, []);
      }
      const shifts = shiftsByDate.get(dateKey);
      if (shifts) {
        shifts.push(shift);
      }
    });

    // Process each date's shifts
    shiftsByDate.forEach((dateShifts: EventInput[], dateKey: string) => {
      const sortedShifts = dateShifts.sort((a: EventInput, b: EventInput) => {
        const aStart = typeof a.start === "string" ? new Date(a.start) : (a.start as Date);
        const bStart = typeof b.start === "string" ? new Date(b.start) : (b.start as Date);
        return aStart.getTime() - bStart.getTime();
      });

      const config = allDates.get(dateKey) || {
        calendar_date: dateKey,
        is_locked: lockedDays.has(dateKey),
      };

      sortedShifts.forEach((shift: EventInput, index: number) => {
        const shiftNumber = index + 1;
        const startKey = `shift_${shiftNumber}_start`;
        const endKey = `shift_${shiftNumber}_end`;

        const newDates = convertEventToTimeZone(shift, timezone);

        // Gestione sia di + che di -
        const dateStartStr = (newDates.start as string).replace(/([+-]\d{2}:\d{2})$/, "") + "Z";
        const startUTC = new Date(dateStartStr).getTime();
        const dateEndStr = (newDates.end as string).replace(/([+-]\d{2}:\d{2})$/, "") + "Z";
        const endUTC = new Date(dateEndStr).getTime();

        config[startKey] = startUTC;
        config[endKey] = endUTC;
      });

      allDates.set(dateKey, config);
    });

    const editedConfiguration = Array.from(allDates.values());

    const overlapError = validateOverlaps(editedConfiguration);
    if (overlapError) {
      toast.error(`${translate("shifts_configuration.toast.overlap")}`);
      return;
    }

    console.log(editedConfiguration);

    editShiftsConfiguration(editedConfiguration, {
      onSuccess: () => {
        setIsEditMode(false);
      },
    });
  };

  const handleCopyWeek = () => {
    // Get current week's view dates
    const calendarApi = calendarRef.current?.getApi();
    if (!calendarApi) return;

    const start = calendarApi.view.currentStart;
    const end = calendarApi.view.currentEnd;

    // Filter shifts for current week only
    const currentWeekShifts = currentShifts.filter((shift) => {
      const shiftDate = new Date(shift.start as string);
      return shiftDate >= start && shiftDate < end;
    });

    // Create configuration by date similar to save payload
    const configByDate = new Map();

    currentWeekShifts.forEach((shift) => {
      const start = new Date(shift.start as string);
      const dateKey = start.toISOString().split("T")[0];
      const shiftNumber = shift.title?.match(/\d+$/)?.[0];

      if (!shiftNumber) return;

      const config = configByDate.get(dateKey) || {
        calendar_date: dateKey,
        is_locked: shift.is_locked || false,
      };

      const startKey = `shift_${shiftNumber}_start`;
      const endKey = `shift_${shiftNumber}_end`;

      config[startKey] = Math.floor(new Date(shift.start as string).getTime() / 1000) * 1000;
      config[endKey] = Math.floor(new Date(shift.end as string).getTime() / 1000) * 1000;

      configByDate.set(dateKey, config);
    });

    const weekConfiguration = Array.from(configByDate.values());
    console.log("Week configuration to be set as default:", weekConfiguration);

    setWeekAsDefault(weekConfiguration);
    setShowCopyWeekModal(false);
  };

  const handleCancel = () => {
    // Reset shifts to original state
    setTempShifts(currentShifts);

    // Reset locked days to original state
    const originalLockedDays = new Set(
      currentShifts
        .filter((shift) => shift.is_locked)
        .map((shift) => {
          const date = new Date(shift.start as string);
          return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(
            date.getDate(),
          ).padStart(2, "0")}`;
        }),
    );
    setLockedDays(originalLockedDays);
    setShowExtraDay(false);
    // Exit edit mode
    setIsEditMode(false);
  };

  const getValidRange = useCallback(() => {
    const now = new Date();
    return {
      start: new Date(now.getFullYear() - 1, now.getMonth(), now.getDate()),
      end: new Date(now.getFullYear() + 1, now.getMonth(), now.getDate()),
    };
  }, []);

  const handleLockToggle = (dateKey: string) => {
    setLockedDays((prev) => {
      const newLockedDays = new Set(prev);
      if (newLockedDays.has(dateKey)) {
        newLockedDays.delete(dateKey);
      } else {
        newLockedDays.add(dateKey);
      }
      return newLockedDays;
    });

    // Aggiorna anche lo stato dei turni esistenti per quel giorno
    setTempShifts((prev) =>
      prev.map((shift) => {
        const shiftDate = new Date(shift.start as string);
        const shiftDateKey = `${shiftDate.getFullYear()}-${String(
          shiftDate.getMonth() + 1,
        ).padStart(2, "0")}-${String(shiftDate.getDate()).padStart(2, "0")}`;

        if (shiftDateKey === dateKey) {
          return {
            ...shift,
            is_locked: !lockedDays.has(dateKey),
          };
        }
        return shift;
      }),
    );
  };

  const renderDayHeader = (dateInfo: { date: Date }) => {
    return (
      <HeaderWithGauge
        dateInfo={dateInfo}
        shifts={isEditMode ? tempShifts : currentShifts}
        handleLockToggle={handleLockToggle}
        isEditMode={isEditMode}
        lockedDays={lockedDays}
        dateFormat={dateFormat}
        formatTimestamp={formatTimestamp}
        currentView={currentView}
      />
    );
  };

  const handleViewChange = (newView: "timeGridWeek" | "dayGridMonth") => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.changeView(newView);
      setCurrentView(newView);
    }
  };

  const ViewToggle = () => (
    <Box sx={{ display: "flex", gap: 1 }}>
      <Button
        disabled={currentView === "timeGridWeek" ? true : false}
        variant={currentView === "timeGridWeek" ? "contained" : "outlined"}
        onClick={() => handleViewChange("timeGridWeek")}
      >
        {translate("durations.week")}
      </Button>
      <Button
        disabled={currentView === "timeGridWeek" ? false : true}
        variant={currentView === "dayGridMonth" ? "contained" : "outlined"}
        onClick={() => handleViewChange("dayGridMonth")}
      >
        {translate("time_selection.Month")}
      </Button>
    </Box>
  );

  const handleDateClick = (arg: DateClickArg) => {
    if (currentView === "dayGridMonth") {
      const calendarApi = calendarRef.current?.getApi();
      if (calendarApi) {
        calendarApi.gotoDate(arg.date);
        calendarApi.changeView("timeGridWeek");
        setCurrentView("timeGridWeek");
      }
    }
  };

  const handleEventClick = (arg: EventClickArg) => {
    if (currentView === "dayGridMonth" && arg.event.start) {
      const calendarApi = calendarRef.current?.getApi();
      if (calendarApi) {
        calendarApi.gotoDate(arg.event.start);
        calendarApi.changeView("timeGridWeek");
        setCurrentView("timeGridWeek");
      }
    }
  };

  return (
    <>
      <Card sx={{ padding: 2 }}>
        <Box>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <WeekStartsOnSelect
                weekStartsOn={weekStartsOn}
                translate={translate}
                enabled={isEditMode && allowEdit}
              />
              <ViewToggle />
              {!isEditMode && allowEdit && currentView === "timeGridWeek" && (
                <Button variant="outlined" endIcon={<Edit />} onClick={handleEditMode}>
                  {translate("actions.edit")}
                </Button>
              )}
              {isEditMode && allowEdit && (
                <>
                  <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                  >
                    {translate("actions.save")}
                  </LoadingButton>
                  <Button variant="outlined" onClick={handleCancel}>
                    {translate("actions.cancel")}
                  </Button>
                </>
              )}
            </Box>
            <Box>
              {allowEdit && currentView === "timeGridWeek" ? (
                <>
                  {isEditMode && (
                    <Button
                      variant="outlined"
                      onClick={() => setShowExtraDay(!showExtraDay)}
                      title={showExtraDay ? "Show 7 days" : "Show 8 days"}
                      sx={{ mr: 1 }}
                    >
                      {showExtraDay
                        ? `${translate("actions.show")} 7 ${translate("durations.day_other")}`
                        : `${translate("shifts_configuration.extends_8_days")}`}
                    </Button>
                  )}
                  <Button
                    disabled={isEditMode}
                    variant="outlined"
                    onClick={() => setShowCopyWeekModal(true)}
                    title={translate("actions.set_week_as_default")}
                  >
                    {translate("actions.set_week_as_default")}
                  </Button>
                </>
              ) : null}
              <IconButton onClick={() => setShowInfoModal(true)}>
                <InfoOutlined />
              </IconButton>
            </Box>
          </Box>
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: "title",
              center: "",
              right: "today prev,next",
            }}
            initialView={currentView}
            eventResizableFromStart={isEditMode}
            allDaySlot={false}
            editable={isEditMode}
            selectable={isEditMode}
            selectMirror={false}
            dayMaxEvents={true}
            weekends={true}
            eventOverlap={false}
            dateClick={handleDateClick}
            eventClick={handleEventClick}
            firstDay={weekStartsOn}
            eventContent={(arg) =>
              RenderEventContent(arg, isEditMode, handleDelete, translate, timeFormat)
            }
            events={
              isEditMode
                ? tempShifts.map((event) => ({
                    ...event,
                    editable:
                      !isInPast(new Date(event.start as string)) &&
                      new Date(event.start as string) > new Date(),
                    resizable:
                      !isInPast(new Date(event.start as string)) &&
                      new Date(event.start as string) > new Date(),
                    interactive:
                      !isInPast(new Date(event.start as string)) &&
                      new Date(event.start as string) > new Date(),
                  }))
                : currentShifts.map((event) => ({
                    ...event,
                    editable: false,
                    resizable: false,
                    interactive: false,
                  }))
            }
            titleFormat={(args) => formatRange(args)}
            locale={language}
            locales={allLocales}
            nextDayThreshold="24:00:00"
            slotMinTime="00:00:00"
            slotMaxTime="24:00:00"
            contentHeight="auto"
            slotLabelFormat={{
              hour: "numeric",
              minute: "2-digit",
              omitZeroMinute: true,
              hour12: timeFormat === "12h",
            }}
            timeZone={timezone}
            eventDrop={handleEventDrop}
            eventResize={handleEventResize}
            select={handleSelect}
            validRange={getValidRange()}
            dayHeaderContent={renderDayHeader}
            views={{
              dayGridMonth: {
                dayMaxEventRows: true,
                eventDisplay: "auto",
                fixedWeekCount: false,
                displayEventEnd: true,
                eventTimeFormat: {
                  hour: "numeric",
                  minute: "2-digit",
                  omitZeroMinute: false,
                  hour12: timeFormat === "12h",
                },
              },
              timeGridWeek: {
                type: "timeGrid",
                duration: isEditMode ? { weeks: 1, days: showExtraDay ? 1 : 0 } : { weeks: 1 },
                firstDay: weekStartsOn,
                displayEventEnd: true,
                eventTimeFormat: {
                  hour: "numeric",
                  minute: "2-digit",
                  omitZeroMinute: false,
                  hour12: timeFormat === "12h",
                },
              },
            }}
            dateAlignment="week"
          />
          <Dialog open={showCopyWeekModal} onClose={() => setShowCopyWeekModal(false)}>
            <Box p={3}>
              <Typography variant="h6" gutterBottom>
                {translate("actions.set_week_as_default")}
              </Typography>
              <Typography>
                {translate("shifts_configuration.copy_week_as_default_message")}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 3 }}>
                <Button onClick={() => setShowCopyWeekModal(false)} variant="outlined">
                  {translate("acceptance_test.close")}
                </Button>
                <Button variant="contained" color="primary" onClick={handleCopyWeek}>
                  {translate("actions.confirm")}
                </Button>
              </Box>
            </Box>
          </Dialog>
          <ShiftConfiguratorGuide
            open={showInfoModal}
            onClose={() => setShowInfoModal(false)}
            translate={translate}
          />
        </Box>
      </Card>
    </>
  );
}
