import {
  HighchartsChart,
  Chart,
  XAxis,
  YAxis,
  Tooltip,
  Title,
  Legend,
  BellCurveSeries,
  LineSeries,
  HistogramSeries,
} from "react-jsx-highcharts";

import { HighchartsCustomProvider } from "../highcharts";
import { Fragment } from "react";
import { useTranslate } from "@/i18n/config";

interface BellCurveProps {
  title: string;
  bellCurveData: { name: string; data: number[] }[];
  benchmark?: {
    setpoint?: number;
    minWarn?: number;
    minErr?: number;
    maxWarn?: number;
    maxErr?: number;
  };
  histogram: boolean;
  uom?: string;
  xAxisTitle?: string;
}

// const COLORS = [
//   "#f28f43",
//   "#02c220",
//   "#1aadce",
//   "#77a1e5",
//   "#aafcff",
//   "#ce3b6c",
//   "#c2fc2f",
// ];

const COLORS = ["cyan", "pink", "orange", "green"];

export const BellCurve = ({
  title,
  bellCurveData,
  benchmark,
  histogram,
  uom,
  xAxisTitle,
}: BellCurveProps) => {
  const translate = useTranslate();
  return (
    <HighchartsCustomProvider>
      <HighchartsChart accessibility={{ enabled: false }} plotOptions={{}}>
        <Chart backgroundColor={"transparent"} zooming={{ type: "x" }} />
        <Title style={{ color: "white" }} align="left">
          {title}
        </Title>
        <Tooltip useHTML />
        <Legend
          itemStyle={{ color: "white" }}
          itemHoverStyle={{ color: "lightblue" }}
          // symbolRadius={0}
        />
        <XAxis
          title={{ text: `${xAxisTitle} (${uom})`, style: { color: "white" } }}
          plotLines={[
            {
              color: "#1ccf04",
              value: benchmark?.setpoint,
              zIndex: 2,
              width: 2,
            },
          ]}
          plotBands={[
            {
              from: 0,
              to: benchmark?.minErr,
              color: "rgba(224, 9, 9, 0.2)",
              zIndex: 2,
            },
            {
              from: benchmark?.minErr,
              to: benchmark?.minWarn,
              color: "rgba(224, 185, 9, 0.2)",
              zIndex: 2,
            },
            {
              from: benchmark?.maxWarn,
              to: benchmark?.maxErr,
              color: "rgba(224, 185, 9, 0.2)",
              zIndex: 2,
            },
            {
              from: benchmark?.maxErr,
              to: Infinity,
              color: "rgba(224, 9, 9, 0.2)",
              zIndex: 2,
            },
          ]}
          alignTicks={false}
        ></XAxis>
        <YAxis
          title={{
            text: `${translate("filler.probability_density")}`,
            style: { color: "white" },
          }}
          labels={{
            style: { color: "white" },
          }}
        >
          {bellCurveData &&
            bellCurveData.length > 0 &&
            bellCurveData.map((series, index) => (
              <Fragment key={index}>
                <BellCurveSeries
                  name={series.name}
                  baseSeries={index * 2 + 1}
                  fillColor="transparent"
                  color={COLORS[index]}
                  tooltip={{
                    headerFormat: "",
                    pointFormatter: function (this) {
                      const value = (this.y || 0).toFixed(2);
                      return `
                        <span style='color:${this.color}'>●</span> <b>${
                          this.series.name
                        }</b><br>
                        value: ${this.x.toFixed(2)} ${uom}<br>
                        probability density: ${value}
                        `;
                    },
                  }}
                  id={`histogram-${index}`}
                />
                <LineSeries
                  data={series.data}
                  visible={false}
                  showInLegend={false}
                />
              </Fragment>
            ))}
        </YAxis>
        {histogram && (
          <YAxis
            title={{ text: "Frequency", style: { color: "white" } }}
            labels={{
              style: { color: "white" },
            }}
            opposite
          >
            {bellCurveData &&
              bellCurveData.length > 0 &&
              bellCurveData.map((series, index) => (
                <Fragment key={index}>
                  <HistogramSeries
                    name={series.name}
                    linkedTo={`histogram-${index}`}
                    baseSeries={index * 2 + 1}
                    // binsNumber={5}
                    color={COLORS[index]}
                    opacity={0.6}
                    borderColor={"transparent"}
                    tooltip={{
                      pointFormatter: function (this) {
                        return `
                      <span style='color:${this.color}'>●</span> <b>${
                        this.series.name
                      }</b><br>
                      range: ${this.x.toFixed(2)} - ${this.x2?.toFixed(2)}<br>
                      count: ${this.y}
                      `;
                      },
                    }}
                  />
                  <LineSeries
                    data={series.data}
                    visible={false}
                    showInLegend={false}
                  />
                </Fragment>
              ))}
          </YAxis>
        )}
      </HighchartsChart>
    </HighchartsCustomProvider>
  );
};
