import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { Card } from "@/components/Layout/Card";
import { NavButton } from "@/components/NavButton";
import { SelectTimeSelection } from "@/components/SelectTimeSelection";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useTranslate } from "@/i18n/config";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { Alert, Snackbar, Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useGetConfigurableOeeData } from "../api/useGetConfigurableOeeData";
import { ConfigurationStatesContainer } from "../components/ConfigurationStatesContainer";
import { KpiInfoLegend } from "../components/KpiInfoLegend";
import { ConfigurableLoaderLayout } from "../components/Layout/LoaderLayout";
import { TopRow } from "../components/TopRow";
import { ViewStatesContainer } from "../components/ViewStatesContainer";
import { ConfigurationContextProvider } from "../context/ConfigurationContext";
import { useEditStore } from "../store/edit-management-store";
import { convertKpiToArray, customBarChartColors } from "../utils/utilities";
import { fileNameFactory } from "@/utils/fileNameFactory";
import { useMachineContext } from "@/context/machine-context";

export const ConfigurableOeeViewLive = () => {
  const translate = useTranslate();
  const { data, isLoading } = useGetConfigurableOeeData();
  const { machine } = useMachineContext();
  const machineName = machine?.machine;

  const { isEditable } = useEditStore((state) => state);
  const { open, isOpen, close } = useDisclosure();

  return (
    <>
      <MachineViewNavBar>
        <SelectTimeSelection
          exclude={[
            "Custom",
            "Batch",
            "Month",
            "Recipe",
            "SixMonths",
            "ThreeMonths",
            "Year",
          ]}
        />
        <NavButton
          to="/configurable-oee/historic"
          text={translate("view_historic")}
        />
      </MachineViewNavBar>
      {isLoading ? <ConfigurableLoaderLayout /> : null}
      {data ? (
        <Stack gap={2}>
          <TopRow
            standardAvailability={data.standardAvailability}
            standardOee={data.standardOee}
            customAvailability={data.customAvailability}
            customOee={data.customOee}
            pieData={data.pieData}
            standardPerformance={data.standardPerformance}
            customPerformance={data.customPerformance}
          />
          {!isEditable ? (
            <ViewStatesContainer defaultStates={data.statesInfo} />
          ) : (
            <ConfigurationContextProvider
              key={data.calculatedAt}
              defaultStates={data.statesInfo ?? []}
              productionTimeValue={data.productionTime}
              qualityValue={data.quality}
              performanceValue={data.performance}
            >
              <ConfigurationStatesContainer showNotification={() => open()} />
            </ConfigurationContextProvider>
          )}
          <Grid2 container columns={12} spacing={2} alignItems="stretch">
            <Grid2 xs={12} md={9.8}>
              <Card>
                <BarChart.Custom
                  title=""
                  uom=""
                  tooltip={{
                    enabled: true,
                  }}
                  filename={fileNameFactory({
                    name: "configurable_oee",
                    machine: machineName,
                  })}
                  legend={{ enabled: true }}
                  categories={[
                    translate("kpi.oee"),
                    translate("kpi.availability"),
                    translate("kpi.performance"),
                    translate("machine.idle_time"),
                    translate("machine.downtime"),
                  ]}
                  series={[
                    {
                      name: translate("configurable_oee.standard"),
                      type: "column",
                      color: "#bbbbbb",
                      data: convertKpiToArray(data.comparisonKpi.standardKpi),
                    },
                    {
                      name: translate("configurable_oee.custom"),
                      type: "column",
                      data: convertKpiToArray(data.comparisonKpi.customKpi).map(
                        (value, index) => {
                          return {
                            name: value[0],
                            y:
                              typeof value[1] === "string"
                                ? parseFloat(value[1])
                                : value[1],
                            color: customBarChartColors[index],
                          };
                        },
                      ),
                      pointPadding: 0.1,
                      pointPlacement: -0.1,
                    },
                  ]}
                />
              </Card>
            </Grid2>
            <Grid2 xs={12} md={2.2}>
              <KpiInfoLegend comparisonKpi={data.comparisonKpi} />
            </Grid2>
          </Grid2>
        </Stack>
      ) : null}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={isOpen}
        autoHideDuration={5000}
        onClose={close}
      >
        <Alert severity="success">
          {translate("configurable_oee.configured_successfully")}
        </Alert>
      </Snackbar>
    </>
  );
};
