import { formatNumber } from "@/utils/formatNumber";

import {
  HighchartsChart,
  Chart,
  XAxis,
  YAxis,
  Tooltip,
  Title,
  Legend,
  AreaSplineRangeSeries,
} from "react-jsx-highcharts";
import { HighchartsCustomProvider } from "../highcharts";

interface ViolinPlotProps {
  title: string;
  categories: string[];
  uom: string;
  rawData: number[][];
  violinData: number[][];
}

/*
Violin function
Author: Armando Venturi
*/
const processViolin = (
  step: number,
  precision: number,
  densityWidth: number,
  ...args: number[][]
) => {
  let xiData: number[] = [];
  //process the xi
  const prcessXi = (args: number[][]) => {
    const tempXdata = [];
    const tileSteps = 6; //Nbr of point at the top and end of the violin
    let min = Infinity,
      max = -Infinity;

    //process the range of the data set
    args.forEach((e) => {
      min = Math.min(min, Math.min(...e));
      max = Math.max(max, Math.max(...e));
    });

    for (let i = min - tileSteps * step; i < max + tileSteps * step; i++) {
      tempXdata.push(i);
    }
    return tempXdata;
  };
  xiData = prcessXi(args);

  //the KDE gaussian function
  const kdeProcess = (xi: number, u: number) => {
    return (1 / Math.sqrt(2 * Math.PI)) * Math.exp(Math.pow(xi - u, 2) / -2);
  };
  let gap = -1;
  //Create the upper and lower line of the violin
  const violinProcess = (dataSource: number[]) => {
    const data = [];
    const N = dataSource.length;

    gap++;
    for (let i = 0; i < xiData.length; i++) {
      let temp = 0;
      for (let j = 0; j < dataSource.length; j++) {
        temp = temp + kdeProcess(xiData[i], dataSource[j]);
      }
      data.push([xiData[i], (1 / N) * temp]);
    }

    return data.map((violinPoint, i) => {
      if (violinPoint[1] > precision) {
        return [
          xiData[i],
          -(violinPoint[1] * densityWidth) + gap,
          violinPoint[1] * densityWidth + gap,
        ];
      } else {
        return [xiData[i], null, null];
      }
    });
  };

  const results: number[][][] = [];
  let index = 0;

  args.forEach((e) => {
    results.push([]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    results[index] = violinProcess(e).slice() as any;
    index++;
  });
  return { xiData, results };
};

const step = 1,
  precision = 0.00000000001,
  width = 1;

const COLORS = ["cyan", "pink", "orange", "green"];

export const ViolinPlot = ({
  title,
  categories,
  uom,
  rawData,
  violinData,
}: ViolinPlotProps) => {
  const processedData = processViolin(step, precision, width, ...rawData);

  return (
    <HighchartsCustomProvider>
      <HighchartsChart accessibility={{ enabled: false }} plotOptions={{}}>
        <Chart
          backgroundColor={"transparent"}
          type="areasplinerange"
          inverted
        />
        <Title style={{ color: "white" }} align="left">
          {title}
        </Title>
        <Legend
          itemStyle={{ color: "white" }}
          itemHoverStyle={{ color: "white" }}
        />
        <Tooltip useHTML />
        <XAxis labels={{ format: "{value} " + uom }} reversed={false}></XAxis>
        <YAxis
          title={{ text: null }}
          categories={categories}
          startOnTick={false}
          endOnTick={false}
          gridLineWidth={0}
        >
          {processedData.results.map((dataset, i) => (
            <AreaSplineRangeSeries
              key={i}
              name={categories[i]}
              color={COLORS[i]}
              data={dataset}
              tooltip={{
                pointFormatter: function (this) {
                  return `<span style='color:${this.color};'>●</span><b>${
                    this.series.name
                  }</b>
                    <table>
                      <tr>
                        <td>Max:</td>
                        <td>${formatNumber(violinData[i][4])} ${uom}</td>
                      </tr>
                      <tr>
                        <td>Q 3:</td>
                        <td>${formatNumber(violinData[i][3])} ${uom}</td>
                      </tr>
                      <tr>
                        <td>Median:</td>
                        <td>${formatNumber(violinData[i][2])} ${uom}</td>
                      </tr>
                      <tr>
                        <td>Q 1:</td>
                        <td>${formatNumber(violinData[i][1])} ${uom}</td>
                      </tr>
                      <tr>
                        <td>Min:</td>
                        <td>${formatNumber(violinData[i][0])} ${uom}</td>
                      </tr>
                    </table>`;
                },
              }}
            />
          ))}
        </YAxis>
      </HighchartsChart>
    </HighchartsCustomProvider>
  );
};
