import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import {
  ComparisonKpiType,
  KpiProperty,
} from "../api/useGetConfigurableOeeData";
import { useKpiDifferences } from "../hooks/useKpiDifferences";
import { Counter } from "../hooks/useNumberAnimation";

export const KpiInfoLegend = ({
  comparisonKpi,
}: {
  comparisonKpi: ComparisonKpiType;
}) => {
  const kpiInfos = useKpiDifferences(comparisonKpi);

  const prevKpiInfosRef = useRef<KpiProperty>({ ...kpiInfos });
  const translate = useTranslate();

  useEffect(() => {
    prevKpiInfosRef.current = { ...kpiInfos };
  }, [kpiInfos]);

  return (
    <Card sx={{ height: "100%" }}>
      <Stack spacing={1.5} divider={<Divider />}>
        <Typography variant="h6" fontWeight="bold" align={"center"}>
          {translate("configurable_oee.custom")} vs{" "}
          {translate("configurable_oee.standard")}
        </Typography>
        <Stack direction="row" width="100%">
          <Box flex={4}>{translate("kpi.oee")}</Box>
          <Counter
            from={prevKpiInfosRef.current.oee}
            to={kpiInfos.oee}
            reverse={false}
          />
        </Stack>
        <Stack direction="row" width="100%">
          <Box flex={4}>{translate("kpi.availability")}</Box>
          <Counter
            from={prevKpiInfosRef.current.availability}
            to={kpiInfos.availability}
            reverse={false}
          />
        </Stack>
        <Stack direction="row" width="100%">
          <Box flex={4}>{translate("kpi.performance")}</Box>
          <Counter
            from={prevKpiInfosRef.current.performance}
            to={kpiInfos.performance}
            reverse={false}
          />
        </Stack>
        <Stack direction="row" width="100%">
          <Box flex={4}>{translate("machine.idle_time")}</Box>
          <Counter
            from={prevKpiInfosRef.current.inactiveTime}
            to={kpiInfos.inactiveTime}
            reverse={true}
          />
        </Stack>
        <Stack direction="row" width="100%">
          <Box flex={4}>{translate("machine.downtime")}</Box>
          <Counter
            from={prevKpiInfosRef.current.downtime}
            to={kpiInfos.downtime}
            reverse={true}
          />
        </Stack>
      </Stack>
    </Card>
  );
};
