import { ReactNode, useState, useRef, useEffect } from "react";
import { IconButton, Tooltip, styled, Paper } from "@mui/material";
import {
  PersonOutline,
  PrecisionManufacturingOutlined,
} from "@mui/icons-material";
import { useTranslate } from "@/i18n/config";
import { timezoneStore, TimezoneType } from "@/store/useTimezoneStore";
import { ClockIcon } from "../CustomIcons/ClockIcon";
import { motion, AnimatePresence } from "framer-motion";

const MenuContainer = styled(Paper)(({ theme }) => ({
  position: "absolute",
  top: "100%",
  left: "50%",
  transform: "translateX(-50%)",
  width: theme.spacing(6),
  backgroundColor: "#0d1626",
  borderRadius: "0 0 24px 24px",
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(1.5),
  backgroundImage: "none",
  overflow: "hidden",
}));

const MenuItemButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "32px",
  height: "32px",
  "&.selected": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const MotionMenuContainer = motion(MenuContainer);
const MotionMenuItem = motion.div;

interface TimezoneOption {
  label: string;
  value: "user" | "machine";
  icon: ReactNode;
}

export const Timezone: React.FC = () => {
  const translate = useTranslate();
  const { timezoneType, setTimezoneType, setTimezone, machineTimezone } =
    timezoneStore();

  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const options: TimezoneOption[] = [
    {
      label: translate("timezone.user_utc"),
      value: "user",
      icon: <PersonOutline fontSize="small" />,
    },
    {
      label: translate("timezone.machine_utc"),
      value: "machine",
      icon: <PrecisionManufacturingOutlined fontSize="small" />,
    },
  ];

  const handleTimezone = (selectedValue: TimezoneType) => {
    const newTimezone =
      selectedValue === "machine"
        ? machineTimezone
        : Intl.DateTimeFormat().resolvedOptions().timeZone;

    setTimezoneType(selectedValue);
    setTimezone(newTimezone);
    setIsOpen(false);
  };

  const menuVariants = {
    hidden: {
      height: 0,
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    },
    visible: {
      height: "auto",
      transition: {
        duration: 0.2,
        ease: "easeInOut",
        staggerChildren: 0.1,
        when: "beforeChildren",
      },
    },
    exit: {
      height: 0,
      transition: {
        duration: 0.2,
        ease: "easeInOut",
        when: "afterChildren",
      },
    },
  };

  const itemVariants = {
    hidden: {
      opacity: 0,
      y: -10,
      transition: { duration: 0.1 },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.1 },
    },
    exit: {
      opacity: 0,
      y: -10,
      transition: { duration: 0.1 },
    },
  };

  return (
    <div style={{ position: "relative" }}>
      <IconButton
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        sx={{
          padding: 0.5,
          "&:hover": {
            backgroundColor: "action.hover",
          },
        }}
      >
        <ClockIcon />
      </IconButton>

      <AnimatePresence>
        {isOpen && (
          <MotionMenuContainer
            ref={menuRef}
            variants={menuVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            elevation={3}
          >
            {options.map((option) => (
              <MotionMenuItem key={option.label} variants={itemVariants}>
                <Tooltip title={option.label} placement="right" arrow>
                  <MenuItemButton
                    onClick={() => handleTimezone(option.value)}
                    className={timezoneType === option.value ? "selected" : ""}
                  >
                    {option.icon}
                  </MenuItemButton>
                </Tooltip>
              </MotionMenuItem>
            ))}
          </MotionMenuContainer>
        )}
      </AnimatePresence>
    </div>
  );
};
