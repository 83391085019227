import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { NivoSolidGauge } from "@/components/highcharts/gauge/NivoSolidGauge";
import { ShowEmptyData } from "@/components/ShowEmptyData";
import { BasicTable } from "@/components/BasicTable";
import { colors } from "@/styles/colors";
import { StringHelpers } from "@/utils/string-helpers";
import { Box, Stack, Typography } from "@mui/material";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid-premium";
import { Duration } from "luxon";
import { useNavigate } from "react-router-dom";
import { useGetFreezeSubphases } from "../../api/useGetFreezeSubphases";
import { SmallAnalyticContainerLayout } from "../../components/AnalyticContainer/AnalyticContainerLayout";
import { ComposableWidget } from "../../components/ComposableWidget";
import { useLyoContext } from "../../context/lyo-context";
import { Batch } from "../../types";
import { useFormatTimestamp } from "@/utils/TimeHelpers";
import { Card } from "@/components/Layout/Card";
import { Translations, useTranslate } from "@/i18n/config";
import { XRangeChart } from "@/components/highcharts/xrange/XRange";
import { z } from "zod";

export const ProductionOverview = () => {
  const translate = useTranslate();
  const { formatTimestamp } = useFormatTimestamp();
  const [state] = useLyoContext();

  const [{ from, to }, dispatch] = useLyoContext();
  const navigate = useNavigate();

  const { filteredBatches } = state;

  const { data: freezeDryingData } = useGetFreezeSubphases({
    batches: filteredBatches,
  });

  if (filteredBatches.length === 0)
    return (
      <Card
        initial={{ opacity: 0.5, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "1.5rem",
        }}
      >
        {translate("select_at_least_one_batch")}
      </Card>
    );

  const gantData = filteredBatches.map((batch) => ({
    x: batch.from,
    x2: batch.to,
    y: 0,
    color: batch.aborted ? colors.lyo.status.bad : colors.lyo.status.good,
    custom: {
      name: batch.batchName,
      batchReference: batch,
    },
  }));

  const categories = StringHelpers.uniqueValues(
    filteredBatches
      .map((batch) => batch.batchName)
      .sort((a, b) => a.localeCompare(b)),
  );

  const barData = filteredBatches.map((batch) => ({
    name: batch.batchName,
    y: batch.batch_duration,
  }));

  const { cols, rows } = generateTableData({
    batches: filteredBatches,
    dateFormatter: formatTimestamp,
    translate: translate,
  });

  function calculateUptimePercentage() {
    const totalUpTime = state.batches.reduce(
      (acc, curr) => acc + curr.batch_duration,
      0,
    );

    const totalCalendarDuration = (+state.to - +state.from) / 1000;

    return Math.min((totalUpTime / totalCalendarDuration) * 100, 100);
  }

  function calculateSuccessfullBatches() {
    const totalSuccessfullBatches = filteredBatches.filter(
      (batch) => !batch.aborted,
    ).length;

    const totalBatches = filteredBatches.length;

    const percentage = (totalSuccessfullBatches / totalBatches) * 100;
    return percentage;
  }

  function calculateMedianOfBatchesDuration({
    batches: batches,
  }: {
    batches: Batch[];
  }) {
    const durations = batches.map((batch) => batch.batch_duration);
    const median =
      (durations.reduce((a, b) => a + b, 0) * 1000) / durations.length;

    return Duration.fromMillis(median);
  }

  return (
    <Stack spacing={2}>
      <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
        <ComposableWidget
          titleSection={() => (
            <Typography fontWeight="bold" textAlign="left" variant="h6">
              {translate("machine.running_time")}
            </Typography>
          )}
          description={() => (
            <>
              <Typography>
                {translate("diagnostics.lyo.time_spent_running_batches")}
              </Typography>
              <Typography fontWeight="bold">
                {translate("diagnostics.lyo.amount_batches_in_view", {
                  amount: filteredBatches.length,
                })}
              </Typography>
            </>
          )}
          chart={() => (
            <NivoSolidGauge
              min={0}
              max={100}
              color={colors.kpi.uptime}
              title="Uptime"
              value={calculateUptimePercentage()}
              CustomLayer={({ bars }) => {
                return (
                  <>
                    <text
                      y="50%"
                      x="50%"
                      textAnchor="middle"
                      dominantBaseline="middle"
                      style={{ fill: "white", fontSize: "12pt" }}
                    >
                      {bars[0].value.toFixed(2)}%
                    </text>
                  </>
                );
              }}
            />
          )}
        />

        <ComposableWidget
          titleSection={() => (
            <Typography fontWeight="bold" textAlign="left" variant="h6">
              {translate("diagnostics.lyo.completed_batches")}
            </Typography>
          )}
          description={() => (
            <Box>
              {translate("diagnostics.lyo.batches_completed_successfully")}
            </Box>
          )}
          chart={() => (
            <NivoSolidGauge
              color={colors.lyo.status.good}
              min={0}
              max={state.filteredBatches.length}
              title={translate("diagnostics.lyo.completed_batches")}
              value={calculateSuccessfullBatches()}
              CustomLayer={({ bars }) => {
                return (
                  <text
                    y="50%"
                    x="50%"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{ fill: "white", fontSize: "12pt" }}
                  >
                    {bars[0].value.toFixed(2)}%
                  </text>
                );
              }}
            />
          )}
        />

        <ComposableWidget
          titleSection={() => (
            <Typography fontWeight="bold" textAlign="left" variant="h6">
              {translate("diagnostics.lyo.average_batch_duration")}
            </Typography>
          )}
          description={() => (
            <Box>
              <Typography>
                {translate("diagnostics.lyo.average_batch_duration_is")}
              </Typography>

              {(() => {
                const d = calculateMedianOfBatchesDuration({
                  batches: state.filteredBatches,
                });

                const [weeks, days, hours] = d.toFormat("w d hh").split(" ");

                return (
                  <>
                    <Stack
                      direction="row"
                      gap={2}
                      justifyContent="space-between"
                      width={100}
                    >
                      <Typography fontWeight="bold">
                        {translate("durations.week_other")}:{" "}
                      </Typography>
                      <Typography fontWeight="bold">{weeks}</Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      gap={2}
                      justifyContent="space-between"
                      width={100}
                    >
                      <Typography fontWeight="bold">
                        {translate("durations.day_other")}:{" "}
                      </Typography>
                      <Typography fontWeight="bold">{days}</Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      gap={2}
                      justifyContent="space-between"
                      width={100}
                    >
                      <Typography fontWeight="bold">
                        {translate("durations.hour_other")}:{" "}
                      </Typography>
                      <Typography fontWeight="bold">{hours}</Typography>
                    </Stack>
                  </>
                );
              })()}
            </Box>
          )}
        />
      </Stack>

      <SmallAnalyticContainerLayout
        initial={{ opacity: 0.5, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
      >
        <XRangeChart
          data={gantData}
          yAxisOptions={{
            type: "datetime",
          }}
          customHeight={150}
          xAxisOptions={{
            min: +from,
            max: +to,
          }}
          seriesOptions={{
            pointWidth: 20,
            cursor: "pointer",
            events: {
              click: function (event: unknown) {
                const batchParsingObject = z
                  .object({
                    point: z.object({
                      custom: z.object({
                        batchReference: Batch,
                      }),
                    }),
                  })
                  .safeParse(event);

                if (!batchParsingObject.success) return;

                const batch =
                  batchParsingObject.data.point.custom.batchReference;
                dispatch({
                  type: "pick batch",
                  selectedBatch: batch,
                });
                navigate("/analytics/lyophilizer/batch/diagnostic");
              },
            },
          }}
          title={translate("diagnostics.lyo.production_overview")}
        />
      </SmallAnalyticContainerLayout>

      <Card
        initial={{ opacity: 0.5, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
      >
        <BarChart.DurationBarchart
          title={translate("diagnostics.lyo.batch_duration")}
          data={barData}
          categories={categories}
          uom="hh:mm:ss"
        />
      </Card>

      <Card
        initial={{ opacity: 0.5, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
      >
        <BasicTable
          columns={cols}
          rows={rows}
          fileName="production_overview_data"
        />
      </Card>

      {freezeDryingData &&
      freezeDryingData.series.every((value) => value.data.length !== 0) ? (
        <Card
          initial={{ opacity: 0.5, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
        >
          <BarChart.GroupedDurationBarchart
            categories={freezeDryingData.categories}
            uom="min"
            title="Freeze drying"
            data={freezeDryingData.series}
          />
        </Card>
      ) : (
        <ShowEmptyData title="Freeze drying" />
      )}
    </Stack>
  );
};

function generateTableData({
  batches,
  dateFormatter,
  translate,
}: {
  batches: Batch[];
  dateFormatter?: (info: {
    timestamp: number;
    fmt?: string;
    skipTimezoneTransform?: boolean;
    skipLocale?: boolean;
  }) => string;
  translate: (name: Translations) => string;
}): {
  cols: GridColDef[];
  rows: GridRowsProp[];
} {
  const cols: GridColDef[] = [
    {
      field: "id",
      headerName: "Batch id",
    },
    {
      field: "from",
      headerName: translate("start"),
      type: "dateTime",
      valueFormatter: ({ value }) => {
        return (
          value &&
          dateFormatter &&
          dateFormatter({
            timestamp: +new Date(value),
            skipTimezoneTransform: true,
          })
        );
      },
      valueGetter: ({ value }) => {
        const date =
          value &&
          dateFormatter &&
          dateFormatter({
            timestamp: +new Date(value),
            skipLocale: true,
          });
        return value ? new Date(date) : null;
      },
    },
    {
      field: "to",
      headerName: translate("end"),
      type: "dateTime",
      valueFormatter: ({ value }) => {
        return (
          value &&
          dateFormatter &&
          dateFormatter({
            timestamp: +new Date(value),
            skipTimezoneTransform: true,
          })
        );
      },
      valueGetter: ({ value }) => {
        const date =
          value &&
          dateFormatter &&
          dateFormatter({
            timestamp: +new Date(value),
            skipLocale: true,
          });
        return value ? new Date(date) : null;
      },
    },
    {
      field: "batch_duration",
      headerName: translate("duration"),
    },
    {
      field: "recipe",
      headerName: translate("machine.recipe"),
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rows: any = batches.map((batch) => ({
    id: batch.batchId,
    from: batch.from,
    batch_duration: Duration.fromMillis(batch.batch_duration * 1000).toFormat(
      "hh:mm:ss",
    ),
    to: batch.to,
    recipe: batch.recipe,
  }));

  return {
    cols,
    rows,
  };
}
