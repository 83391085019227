import { ChartZoomingOptions } from "highcharts/highstock";
import { HighchartsCustomProvider } from "../highcharts";
import {
  HighchartsChart,
  Title,
  Tooltip,
  Legend,
  Chart,
  XAxis,
  YAxis,
  ScatterSeries,
  LineSeries,
} from "react-jsx-highcharts";

export const ScatterAndLine = ({
  title,
  yAxis = [],
  xAxes,
  tooltip = { enabled: true },
  legend = { enabled: false },
  xAxisType = "datetime",
  xAxisOptions,
  scrollbarEnabled = true,
  lineSeries,
  zoomType = "x",
  radius = 4,
}: {
  title: string;
  yAxis: {
    uom: string;
    options?: Highcharts.YAxisOptions;
    series?: Highcharts.SeriesScatterOptions[];
    yAxisTitleOptions?: Highcharts.YAxisTitleOptions;
    thresholds?: {
      value: number;
      color: string;
      text: string;
    }[];
  }[];
  legend?:
    | { enabled: false }
    | { enabled: true; options?: Partial<Highcharts.LegendOptions> };
  tooltip?:
    | { enabled: false }
    | { enabled: true; options?: Partial<Highcharts.TooltipOptions> };
  xAxes?: Highcharts.XAxisOptions[];
  xAxisType?: Highcharts.AxisTypeValue;
  xAxisOptions?: Highcharts.XAxisOptions;
  scrollbarEnabled?: boolean;
  lineSeries: Highcharts.SeriesLineOptions[];
  zoomType?: ChartZoomingOptions["type"];
  radius?: number;
}) => {
  return (
    <HighchartsCustomProvider>
      <HighchartsChart
        accessibility={{ enabled: false }}
        plotOptions={{}}
        time={{ useUTC: false }}
      >
        <Chart
          backgroundColor={"transparent"}
          type=""
          zooming={{ type: zoomType }}
          style={{
            color: "white",
          }}
        />
        <Title style={{ color: "white" }} align="left">
          {title}
        </Title>

        {tooltip.enabled && <Tooltip shared useHTML {...tooltip.options} />}

        {legend.enabled && (
          <Legend
            itemStyle={{
              color: "white",
            }}
            {...legend.options}
          ></Legend>
        )}
        {xAxes ? (
          xAxes.map((xAxis, i) => <XAxis key={"xAxis" + i} {...xAxis}></XAxis>)
        ) : (
          <XAxis
            scrollbar={{
              enabled: scrollbarEnabled,
              liveRedraw: true,
              barBackgroundColor: "#0D1626",
              barBorderRadius: 7,
              barBorderWidth: 0,
              buttonBackgroundColor: "#0D1626",
              buttonBorderWidth: 1,
              buttonBorderRadius: 7,
              buttonArrowColor: "#0D1626",
              buttonBorderColor: "#3f4961",
              rifleColor: "#0D1626",
              trackBackgroundColor: "#3f4961",
              trackBorderWidth: 1,
              trackBorderRadius: 8,
              trackBorderColor: "none",
            }}
            type={xAxisType}
            {...xAxisOptions}
          ></XAxis>
        )}
        {yAxis?.map(
          (
            { uom, series = [], thresholds = [], yAxisTitleOptions, options },
            i,
          ) => (
            <YAxis
              key={i}
              labels={{ format: "{value} " + uom }}
              showEmpty
              title={yAxisTitleOptions}
              type={"linear"}
              plotLines={thresholds?.map((t) => ({
                value: t.value,
                color: t.color,
                width: 2,
                zIndex: 5,
                acrossPanes: true,
                dashStyle: "Dash",
                label: {
                  text: t.text,
                  align: "right",
                  x: -10,
                  style: {
                    color: t.color,
                  },
                },
              }))}
              {...options}
              alignTicks={false}
            >
              {series.map((serie, i) => (
                <ScatterSeries
                  key={`scatter-${i}`}
                  {...serie}
                  borderColor="none"
                  marker={{ enabled: true, radius }}
                />
              ))}
              {lineSeries.map((series, i) => (
                <LineSeries key={`line-${i}`} {...series} />
              ))}
            </YAxis>
          ),
        )}
      </HighchartsChart>
    </HighchartsCustomProvider>
  );
};
