import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { ShiftsConfiguration } from "../types";
import { useFirebaseContext } from "@/context/firebase-context";
import { useTranslate } from "@/i18n/config";

type SetWeekAsDefaultResponse = ThingworxError | SetWeekAsDefaultResult;

const SetWeekAsDefaultResult = z.object({
  response: z.literal(true),
});

export type SetWeekAsDefaultResult = z.infer<typeof SetWeekAsDefaultResult>;

type Payload = {
  machineName: string;
  weekToCopy: ShiftsConfiguration[];
};

export const useSetWeekAsDefault = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const translate = useTranslate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (weekToCopy: ShiftsConfiguration[]) => {
      const response = await FetchClient<Payload, SetWeekAsDefaultResponse>({
        appKey,
        url: "dig.c.shiftsConfiguration_thing/Services/setWeekAsDefault",
        payload: {
          machineName: machine!.machine ?? "",
          weekToCopy: weekToCopy,
        },
      });

      if (!response.response) throw new Error(response.errorString);
    },

    onSuccess: () => {
      toast.success(translate("shifts_configuration.toast.success_future_shifts"));
      queryClient.invalidateQueries({
        queryKey: ["get-shifts-configuration", machine?.machine],
      });
    },
    onError: () => {
      toast.error(translate("shifts_configuration.toast.error_future_shifts"));
    },
  });
};
