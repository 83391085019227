import { EventContentArg } from "@fullcalendar/core";
import { Box, Typography, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { TranslationKey } from "@/i18n/config";
import { TimeFormat } from "@/store/useDateTimeFormat";

export const RenderEventContent = (
  eventContent: EventContentArg,
  isEditMode: boolean,
  onDelete: (eventId: string) => void,
  translate: (key: TranslationKey) => string,
  timeFormat: TimeFormat,
) => {
  const now = new Date();
  const eventStart = new Date(eventContent.event.start!);
  const eventEnd = new Date(eventContent.event.end!);
  const isPast = eventStart < now;
  const isMonthView = eventContent.view.type === "dayGridMonth";

  const needsNextDayIndicator = () => {
    if (!isMonthView) return false;

    // Convertiamo in UTC per avere gli orari reali
    const startHourUTC = eventStart.getUTCHours();
    const endHourUTC = eventEnd.getUTCHours();
    const startMinutesUTC = eventStart.getUTCMinutes();
    const endMinutesUTC = eventEnd.getUTCMinutes();

    // Calcoliamo il tempo totale in minuti per ogni orario
    const startTimeInMinutes = startHourUTC * 60 + startMinutesUTC;
    const endTimeInMinutes = endHourUTC * 60 + endMinutesUTC;

    // Se l'ora di fine è minore dell'ora di inizio, significa che il turno si estende al giorno successivo
    return endTimeInMinutes < startTimeInMinutes;
  };

  let timeText = eventContent.timeText;
  if (needsNextDayIndicator()) {
    const [startTime] = eventContent.timeText.split(" - ");
    const endHours = eventEnd.getUTCHours();
    const endMinutes = eventEnd.getUTCMinutes().toString().padStart(2, "0");

    if (timeFormat === "12h") {
      // Converti in formato 12h
      const period = endHours >= 12 ? "PM" : "AM";
      const hours12 = endHours % 12 || 12; // Converte 0 in 12 per mezzanotte
      timeText = `${startTime} - ${hours12}:${endMinutes} ${period} (+1 ${translate(
        "durations.day",
      )})`;
    } else {
      // Formato 24h (come prima)
      const hours24 = endHours.toString().padStart(2, "0");
      timeText = `${startTime} - ${hours24}:${endMinutes} (+1 ${translate("durations.day")})`;
    }
  }

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDelete(eventContent.event.id);
  };

  const formatDuration = (start: Date, end: Date) => {
    const diff = end.getTime() - start.getTime();
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.round((diff % (1000 * 60 * 60)) / (1000 * 60) / 30) * 30;

    if (minutes === 0) {
      return `${translate("duration")}: ${hours} ${
        hours > 1 ? translate("durations.hour_other") : translate("durations.hour")
      }`;
    } else {
      return `${translate("duration")}: ${hours} ${
        hours > 1 ? translate("durations.hour_other") : translate("durations.hour")
      } ${minutes} ${translate("durations.minute_other")}`;
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        padding: "4px",
        backgroundColor: isPast ? "#778899" : "#155077",
        borderRadius: "2px",
        color: isPast ? "#000000" : "#ffffff",
        position: "relative",
        cursor: isEditMode && !isPast && eventStart > now ? "pointer" : "not-allowed",
        pointerEvents: isPast || (eventStart < now && now < eventEnd) ? "none" : "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pr: isEditMode ? 1 : 0,
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          width={"100%"}
          alignItems={"center"}
          marginBottom="4px"
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: "0.875rem",
              fontWeight: isPast ? 400 : 500,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "0 0 4px 4px",
            }}
          >
            {eventContent.event.title}
          </Typography>
          {isMonthView && (
            <Typography
              variant="caption"
              sx={{
                fontSize: "0.6rem",
                padding: "0 4px",
              }}
            >
              {formatDuration(eventStart, eventEnd)}
            </Typography>
          )}
        </Box>
        {isEditMode && !isPast && (
          <Box sx={{ display: "flex", gap: 0.5 }}>
            <IconButton
              size="small"
              onClick={handleDelete}
              sx={{
                padding: 0,
                color: "inherit",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
              }}
            >
              <Delete fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>
      <Typography
        variant="caption"
        sx={{
          display: "block",
          lineHeight: "1",
          color: isPast ? "#000000" : "#ffffff",
          fontSize: "0.65rem",
          padding: "0 4px",
        }}
      >
        {timeText}
      </Typography>
      {!isMonthView && (
        <Typography
          variant="caption"
          sx={{
            fontSize: "0.6rem",
            lineHeight: "1",
            padding: "0 4px",
            justifyContent: isMonthView ? "end" : "start",
            display: "flex",
            marginTop: "4px",
          }}
        >
          {formatDuration(eventStart, eventEnd)}
        </Typography>
      )}
    </Box>
  );
};
