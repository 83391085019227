import {
  HighchartsChart,
  Chart,
  XAxis,
  YAxis,
  Tooltip,
  Title,
  ColumnSeries,
  ScatterSeries,
  LineSeries,
  XRangeSeries,
  Legend,
} from "react-jsx-highcharts";

import { HighchartsCustomProvider } from "../highcharts";

type SeriesConfig =
  | {
      type: "column";
      series: Highcharts.SeriesColumnOptions;
      yAxis?: Highcharts.YAxisOptions;
    }
  | {
      type: "scatter";
      series: Highcharts.SeriesScatterOptions;
      yAxis?: Highcharts.YAxisOptions;
    }
  | {
      type: "xrange";
      series: Highcharts.SeriesXrangeOptions;
      yAxis?: Highcharts.YAxisOptions;
    }
  | {
      type: "line";
      series: Highcharts.SeriesLineOptions;
      yAxis?: Highcharts.YAxisOptions;
    };

interface Config {
  title: string;
  series1: SeriesConfig;
  series2: SeriesConfig;
  xAxisType?: "datetime" | "category";
  xAxisCategories?: string[];
  legend?:
    | { enabled: false }
    | { enabled: true; options?: Partial<Highcharts.LegendOptions> };
  tooltip?:
    | { enabled: false }
    | { enabled: true; options?: Partial<Highcharts.TooltipOptions> };
}

export const CustomChart = ({
  title,
  series1,
  series2,
  xAxisType = "datetime",
  xAxisCategories,
  legend = { enabled: false },
  tooltip = { enabled: false },
}: Config) => {
  return (
    <HighchartsCustomProvider>
      <HighchartsChart accessibility={{ enabled: false }} plotOptions={{}}>
        <Chart
          backgroundColor={"transparent"}
          type="column"
          zooming={{ type: "x" }}
        />
        <Title style={{ color: "white" }} margin={30} align="left">
          {title}
        </Title>
        <Tooltip shared />
        <XAxis
          categories={
            (xAxisType === "category" && xAxisCategories) || undefined
          }
          crosshair
          type={xAxisType}
        ></XAxis>
        <YAxis {...series1.yAxis}>
          {series1.type === "line" ? (
            <LineSeries {...series1.series} />
          ) : series1.type === "column" ? (
            <ColumnSeries {...series1.series} />
          ) : series1.type === "scatter" ? (
            <ScatterSeries {...series1.series} />
          ) : series1.type === "xrange" ? (
            <XRangeSeries {...series1.series} />
          ) : null}
        </YAxis>
        <YAxis {...series2.yAxis}>
          {series2.type === "line" ? (
            <LineSeries {...series2.series} />
          ) : series2.type === "column" ? (
            <ColumnSeries {...series2.series} />
          ) : series2.type === "scatter" ? (
            <ScatterSeries {...series2.series} />
          ) : series2.type === "xrange" ? (
            <XRangeSeries {...series2.series} />
          ) : null}
        </YAxis>
        {legend.enabled && (
          <Legend
            itemStyle={{
              color: "white",
            }}
            {...legend.options}
          ></Legend>
        )}
        {tooltip.enabled && <Tooltip shared useHTML {...tooltip.options} />}
      </HighchartsChart>
    </HighchartsCustomProvider>
  );
};
