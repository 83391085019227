import {
  ChartOptions,
  PlotXrangeOptions,
  XAxisOptions,
  YAxisOptions,
  PointOptionsObject,
  TooltipOptions,
} from "highcharts/highstock";
import {
  HighchartsChart,
  Chart,
  XAxis,
  YAxis,
  Tooltip,
  Title,
  ColumnSeries,
  Pane,
} from "react-jsx-highcharts";
import { HighchartsCustomProvider } from "@/components/highcharts/highcharts";
import { useTranslate } from "@/i18n/config";

interface PolarChartProps {
  title: string;
  data: PointOptionsObject[];
  seriesOptions?: PlotXrangeOptions;
  xAxisOptions?: XAxisOptions;
  yAxisOptions?: YAxisOptions;
  chartOptions?: ChartOptions;
  allowExporting?: boolean;
  tooltipOptions?: TooltipOptions;
}

export const PolarChart = (props: PolarChartProps) => {
  const translate = useTranslate();
  const {
    title,
    data,
    seriesOptions,
    xAxisOptions,
    yAxisOptions,
    chartOptions,
    allowExporting,
    tooltipOptions,
  } = props;

  return (
    <HighchartsCustomProvider>
      <HighchartsChart
        accessibility={{ enabled: false }}
        plotOptions={{
          series: {
            pointStart: 0,
            pointInterval: 30,
          },
          column: {
            pointPadding: 0,
            groupPadding: 0,
          },
        }}
        exporting={{ enabled: allowExporting }}
      >
        <Chart backgroundColor="transparent" polar={true} {...chartOptions} />

        <Title style={{ color: "white" }} align="left">
          {title}
        </Title>

        <Tooltip {...tooltipOptions} />

        <Pane startAngle={0} endAngle={360} />

        <XAxis
          title={{ text: null }}
          {...xAxisOptions}
        />

        <YAxis
          gridLineColor="#333333"
          labels={{
            style: {
              color: "white",
            },
          }}
          title={{ text: null }}
          {...yAxisOptions}
        >
          <ColumnSeries
            name={translate("value")}
            data={data}
            {...seriesOptions}
          />
        </YAxis>
      </HighchartsChart>
    </HighchartsCustomProvider>
  );
};
