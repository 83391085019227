import { Calendar } from "./components/Calendar/Calendar";
import { useTranslate } from "@/i18n/config";
import { useGetShiftsConfiguration } from "./api/useGetShiftsConfiguration";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { Card } from "@/components/Layout/Card";

export const ShiftsConfiguration = () => {
  const { data, isLoading, error } = useGetShiftsConfiguration();
  const translate = useTranslate();

  if (isLoading) {
    return (
      <>
        <MachineViewNavBar />
        <SkeletonCard height={800} />
      </>
    );
  }

  if (error) {
    return (
      <>
        <MachineViewNavBar />
        <Card>{translate("user_feedback.an_error_occurred")}</Card>
      </>
    );
  }

  if (!data) {
    return (
      <>
        <MachineViewNavBar />
        <Card>{translate("user_feedback.no_data_to_display")}</Card>
      </>
    );
  }

  return (
    <>
      <MachineViewNavBar />
      <Calendar data={data.shiftsConfiguration} weekStartsOn={data.weekStartsOn} />
    </>
  );
};
