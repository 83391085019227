import Highcharts from "highcharts/highstock";

import {
  HighchartsChart,
  Chart,
  XAxis,
  YAxis,
  Tooltip,
  Title,
  ParetoSeries,
  ColumnSeries,
} from "react-jsx-highcharts";
import { HighchartsCustomProvider } from "../highcharts";

interface ParetoProps {
  title: string;
  categories: string[];
  pareto: {
    options?: Highcharts.YAxisOptions;
    yAxisTitleOptions?: Highcharts.YAxisTitleOptions;
  };
  column: {
    uom?: string;
    options?: Highcharts.YAxisOptions;
    series?: Highcharts.SeriesColumnOptions;
    yAxisTitleOptions?: Highcharts.YAxisTitleOptions;
  };
  tooltip?:
    | { enabled: false }
    | { enabled: true; options?: Partial<Highcharts.TooltipOptions> };
  scrollbarEnabled?: boolean;
  xAxisMax?: number;
}

export const ParetoChart = ({
  title,
  categories,
  pareto,
  column,
  tooltip = { enabled: false },
  scrollbarEnabled = false,
  xAxisMax,
}: ParetoProps) => {
  return (
    <HighchartsCustomProvider>
      <HighchartsChart accessibility={{ enabled: false }} plotOptions={{}}>
        <Chart backgroundColor={"transparent"} type="column" />
        <Title style={{ color: "white" }} align="left">
          {title}
        </Title>
        {tooltip.enabled && <Tooltip shared useHTML {...tooltip.options} />}
        <XAxis
          crosshair
          categories={categories}
          scrollbar={{
            enabled: scrollbarEnabled,
            liveRedraw: true,
            barBackgroundColor: "#0D1626",
            barBorderRadius: 7,
            barBorderWidth: 0,
            buttonBackgroundColor: "#0D1626",
            buttonBorderWidth: 1,
            buttonBorderRadius: 7,
            buttonArrowColor: "#0D1626",
            buttonBorderColor: "#3f4961",
            rifleColor: "#0D1626",
            trackBackgroundColor: "#3f4961",
            trackBorderWidth: 1,
            trackBorderRadius: 8,
            trackBorderColor: "none",
          }}
          max={xAxisMax}
        ></XAxis>
        <YAxis
          title={{ style: { color: "white" }, ...pareto.yAxisTitleOptions }}
          labels={{ format: "{value}%", style: { color: "white" } }}
          gridLineColor="#333333"
          gridLineWidth={1}
          minPadding={0}
          maxPadding={0}
          opposite
          min={0}
          max={100}
          plotLines={
            column.series?.data && column.series?.data.length > 0
              ? [{ color: "red", width: 2, value: 80 }]
              : []
          }
          {...pareto.options}
        >
          <ParetoSeries
            name="Pareto"
            color="#14a5e3"
            zIndex={10}
            baseSeries={1}
            tooltip={{ valueDecimals: 2, valueSuffix: "%" }}
          />
        </YAxis>
        <YAxis
          title={{ style: { color: "white" }, ...column.yAxisTitleOptions }}
          labels={{
            style: { color: "white" },
            format: column.uom ? `{value} ${column.uom}` : "{value}",
          }}
          gridLineColor="#333333"
          gridLineWidth={1}
          {...column.options}
        >
          <ColumnSeries
            zIndex={2}
            borderColor="transparent"
            color="#F7B500"
            {...column.series}
          />
        </YAxis>
      </HighchartsChart>
    </HighchartsCustomProvider>
  );
};
