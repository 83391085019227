import { HighchartsCustomProvider } from "@/components/highcharts/highcharts";
import {
  HighchartsChart,
  Title,
  XAxis,
  Chart,
  Tooltip,
  YAxis,
  LineSeries,
  PieSeries,
  ScatterSeries,
  XRangeSeries,
  Legend,
  ColumnSeries,
} from "react-jsx-highcharts";
import { AxisTypeValue } from "highcharts";
import { DataVariable } from "../types/variable";
import { Divider, Stack, Typography } from "@mui/material";
import { useFormatTimestamp } from "@/utils/TimeHelpers";
import { Fragment } from "react";

export const CustomChart = ({
  title = "",
  variables,
  min,
  max,
  timezone,
}: {
  title?: string;
  variables: DataVariable[];
  min?: number;
  max?: number;
  timezone?: string;
}) => {
  const groupedVariables = groupByYAxis(variables);

  return (
    <ChartCommonWrapper title={title} min={min} max={max} timezone={timezone}>
      {Object.entries(groupedVariables).map(([yAxis, variables], index) => {
        return (
          <YAxis
            key={yAxis}
            opposite={index % 2 === 0}
            grid={{
              enabled: false,
            }}
            gridLineWidth={0}
            labels={{
              style: { color: "white" },
              format: variables[0].uom
                ? "{value} " + variables[0].uom
                : "{value}" + " units",
            }}
            categories={
              "categories" in variables[0].data
                ? variables[0].data.categories
                : undefined
            }
          >
            {variables.map((variable) => renderSeries(variable))}
          </YAxis>
        );
      })}
    </ChartCommonWrapper>
  );
};

export const AggregableCustomChart = ({
  title,
  variables,
  timezone,
}: {
  title?: string;
  variables: DataVariable[];
  timezone?: string;
}) => {
  const { formatTimestamp } = useFormatTimestamp();
  return (
    <Stack gap={4}>
      <Typography variant="h6">{title}</Typography>
      {variables.map((variable) => {
        return (
          <Fragment key={variable.id}>
            <ChartCommonWrapper
              title={variable.data.name}
              xAxisType="category"
              xAxisCategories={
                variable.series_type === "bar"
                  ? variable.data.categories
                  : undefined
              }
              timezone={timezone}
            >
              <YAxis>
                {renderSeries(variable, formatTimestamp)}
              </YAxis>
            </ChartCommonWrapper>
            <Divider />
          </Fragment>
        );
      })}
    </Stack>
  );
};

const ChartCommonWrapper = ({
  children,
  title,
  xAxisType = "datetime",
  min,
  max,
  xAxisCategories,
  timezone,
}: React.PropsWithChildren<{
  title: string;
  xAxisType?: AxisTypeValue;
  min?: number;
  max?: number;
  xAxisCategories?: string[];
  timezone?: string;
}>) => {
  return (
    <HighchartsCustomProvider>
      <HighchartsChart
        accessibility={{ enabled: false }}
        exporting={{
          enabled: true,
        }}
        time={{ timezone }}
      >
        <Chart
          backgroundColor={"transparent"}
          type="column"
          zooming={{ type: "x" }}
        />
        <Title style={{ color: "white" }} align="left">
          {title}
        </Title>
        <Tooltip />
        <XAxis
          crosshair
          type={xAxisType}
          min={min}
          max={max}
          categories={xAxisCategories}
        />
        {children}

        <Legend />
      </HighchartsChart>
    </HighchartsCustomProvider>
  );
};

const renderSeries = (
  variable: DataVariable,
  dateFormatter?: (info: {
    timestamp: number;
    fmt?: string;
    skipTimezoneTransform?: boolean;
  }) => string,
) => {
  const variable_name = `${variable.machine_id} - ${variable.data.name}`;

  switch (variable.series_type) {
    case "line":
      return (
        <LineSeries
          key={variable.id}
          name={variable_name}
          data={variable.data.values}
          tooltip={{
            valueSuffix: " " + (variable.uom ?? "units"),
          }}
          marker={{
            enabled: false,
            symbol: "circle",
          }}
        />
      );
    case "bar":
      return (
        <Fragment key={variable.id}>
          {variable.data.series.map((data, i) => (
            <ColumnSeries
              key={`${data.name}-${i}`}
              name={variable_name}
              data={data.values}
              borderWidth={0}
              tooltip={{
                valueSuffix: " " + (variable.uom ?? "units"),
              }}
            />
          ))}
        </Fragment>
      );
    case "scatter":
      return (
        <ScatterSeries
          key={variable.id}
          name={variable_name}
          tooltip={{
            // valueSuffix: " " + (variable.uom ?? "units"),

            pointFormatter: function () {
              return `${
                dateFormatter &&
                dateFormatter({
                  timestamp: this.x as number,
                })
              }<br/>${variable.data.categories?.[this.y as number]} ${
                variable.uom ?? ""
              }`;
            },
          }}
          marker={{
            enabled: true,
            symbol: "circle",
          }}
          data={variable.data.values}
        />
      );
    case "xrange":
      return (
        <XRangeSeries
          key={variable.id}
          name={variable_name}
          data={variable.data.values}
        />
      );
    case "pie":
      return (
        <PieSeries
          key={variable.id}
          dataLabels={{
            enabled: false,
          }}
          showInLegend
          borderWidth={0}
          name={variable_name}
          data={variable.data.values}
        />
      );
  }
};

const groupByYAxis = (variables: DataVariable[]) => {
  return variables.reduce(
    (acc, variable) => {
      const { y_axis: yAxis } = variable;
      if (!acc[yAxis]) {
        acc[yAxis] = [];
      }
      acc[yAxis].push(variable);
      return acc;
    },
    {} as Record<string, DataVariable[]>,
  );
};
