/* eslint-disable react-hooks/rules-of-hooks */
import { HighchartsCustomProvider } from "../highcharts";

import {
  Chart,
  ColumnSeries,
  HighchartsChart,
  Legend,
  Title,
  Tooltip,
  XAxis,
  YAxis,
} from "react-jsx-highcharts";
import { renderToString } from "react-dom/server";
import { Duration } from "luxon";
import { FormGroup, FormControlLabel, Switch } from "@mui/material";
import { Switch as SentinelSwitch } from "@/components/Switch";
import { useState, ChangeEvent } from "react";
import { colors } from "@/styles/colors";
import { z } from "zod";
import { useTranslate } from "@/i18n/config";

export const BarchartProps = z.object({
  title: z.string().optional(),
  categories: z.array(z.string()).optional(),
  data: z.array(
    z.object({
      name: z.string(),
      y: z.number(),
    }),
  ),
  thresholds: z
    .array(
      z.object({
        value: z.number(),
        color: z.string(),
        text: z.string(),
      }),
    )
    .optional(),
  uom: z.string(),
});

export type BarchartProps = z.infer<typeof BarchartProps>;

export interface BarchartData {
  name: string;
  y: number;
}

/**
 * Colors for the Barchart used for the golden/value comparison in the asset barchart for the lyo
 */
const COLORS = ["#fa6400", "#ffe455"];

/**
 * Barchart component, groups all the different kind of barcharts
 */
export class BarChart {
  /**
   * should be good for the asset barchart in the lyo page,
   * with the golden/value comparison, check why it's not used and if it can be used instead of the other barchart
   */
  static AssetBarchart({ title, categories, data }: BarchartProps) {
    const dataWithColors = data.map((d, i) => ({ ...d, color: COLORS[i] }));

    return (
      <HighchartsCustomProvider>
        <HighchartsChart
          accessibility={{ enabled: false }}
          exporting={{
            enabled: true,
            buttons: {
              contextButton: {
                menuItems: ["downloadCSV", "downloadXLS"],
              },
            },
          }}
        >
          <Chart
            backgroundColor={"transparent"}
            type="column"
            zooming={{ type: "x" }}
            style={{
              color: "white",
            }}
            alignTicks={false}
          />
          <Title style={{ color: "white" }}>{title}</Title>

          <Tooltip shared />
          <XAxis categories={categories}></XAxis>
          <YAxis
            type="datetime"
            //force all formats to be hour:minute:second
            dateTimeLabelFormats={{
              second: "%H:%M",
              minute: "%H:%M",
              hour: "%H:%M",
              day: "%H:%M",
              week: "%H:%M",
              month: "%H:%M",
              year: "%H:%M",
            }}
          >
            <ColumnSeries
              name="value"
              borderColor="none"
              data={dataWithColors}
            />
          </YAxis>
        </HighchartsChart>
      </HighchartsCustomProvider>
    );
  }

  static LinearBarchart({
    title,
    categories,
    data,
    uom,
    thresholds,
  }: BarchartProps & { uom: string }) {
    return (
      <HighchartsCustomProvider>
        <HighchartsChart accessibility={{ enabled: false }}>
          <Chart
            backgroundColor={"transparent"}
            type="column"
            zooming={{ type: "x" }}
            style={{
              color: "white",
            }}
            alignTicks={false}
          />
          <Title style={{ color: "white" }} align="left">
            {title}
          </Title>

          <Tooltip
            shared
            useHTML
            formatter={function () {
              const batchName = this.points
                ? this.points[0].key
                : "Missing name";

              return renderToString(
                <div
                  style={{
                    width: "200px",
                    display: "flex",
                    flexDirection: "column",
                    gap: ".5rem",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>
                    Batch name: {batchName}
                  </div>

                  {this.points
                    ? this.points.map((point, i) => (
                        <div key={i}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: "&bull;",
                            }}
                            style={{
                              color: point.color?.toString(),
                            }}
                          />
                          <b> {point.series.name}</b>:{" "}
                          <b>
                            {point.y?.toFixed(2)} {uom}
                          </b>
                          <br />
                        </div>
                      ))
                    : null}
                </div>,
              );
            }}
          />
          <XAxis categories={categories}></XAxis>
          <YAxis
            labels={{ format: "{value} " + uom }}
            showEmpty
            plotLines={thresholds?.map((t) => ({
              value: t.value,
              color: t.color,
              width: 2,
              zIndex: 5,
              acrossPanes: true,
              dashStyle: "Dash",
              label: {
                text: t.text,
                align: "right",
                x: -10,
                style: {
                  color: t.color,
                },
              },
            }))}
          >
            <ColumnSeries name="value" borderColor="none" data={data} />
          </YAxis>
        </HighchartsChart>
      </HighchartsCustomProvider>
    );
  }

  static Custom({
    title,
    categories,
    series = [],
    uom,
    thresholds,
    yAxisTitleOptions,
    tooltip = { enabled: false },
    legend = { enabled: false },
    showLogarithmicToggle = false,
    drillDownOptions,
    zooming = { type: "x" },
    scrollbarEnabled = false,
    plotOptions = {},
    xAxisMax,
    yAxisOptions = {},
    height = 300,
    filename, //labelRotation
  }: Omit<BarchartProps, "data"> & {
    uom: string;
    series?: Highcharts.SeriesColumnOptions[];
    yAxisTitleOptions?: Highcharts.YAxisTitleOptions;
    plotOptions?: Highcharts.PlotOptions;
    legend?:
      | { enabled: false }
      | { enabled: true; options?: Partial<Highcharts.LegendOptions> };
    tooltip?:
      | { enabled: false }
      | { enabled: true; options?: Partial<Highcharts.TooltipOptions> };
    showLogarithmicToggle?: boolean;
    drillDownOptions?: Highcharts.DrilldownOptions;
    zooming?: Highcharts.ChartZoomingOptions;
    scrollbarEnabled?: boolean;
    xAxisMax?: number;
    yAxisOptions?: Highcharts.YAxisOptions;
    height?: number;
    filename?: string;
  }) {
    const [showAsLogarithmic, setShowAsLogarithmic] = useState(false);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setShowAsLogarithmic(event.target.checked);
    };

    const translate = useTranslate();

    return (
      <>
        <HighchartsCustomProvider>
          <HighchartsChart
            exporting={{
              filename,
            }}
            accessibility={{ enabled: false }}
            plotOptions={plotOptions}
            chart={{
              height: height,
            }}
            drilldown={{
              allowPointDrilldown: false,
              activeAxisLabelStyle: {
                textDecoration: "none",
                color: "#ccc",
                fontWeight: "normal",
              },
              breadcrumbs: {
                style: {
                  fill: "white",
                  fontFamily: "Montserrat, Helvetica Neue,Arial, sans-serif",
                },

                buttonTheme: {
                  fill: colors.palette.lightBlue,
                  style: {
                    color: "#fff",
                    fontFamily: "Montserrat, Helvetica Neue,Arial, sans-serif",
                  },

                  stroke: "transparent",
                  states: {
                    select: {
                      fill: colors.palette.lightBlue,
                      style: {
                        color: "#fff",

                        fontFamily:
                          "Montserrat, Helvetica Neue,Arial, sans-serif",
                      },
                    },
                    hover: {
                      fill: "orange",
                      style: {
                        fontFamily:
                          "Montserrat, Helvetica Neue,Arial, sans-serif",
                      },
                    },
                  },
                },
              },
              ...drillDownOptions,
            }}
          >
            <Chart
              backgroundColor={"transparent"}
              type="column"
              zooming={zooming}
              style={{
                color: "white",
              }}
              alignTicks={false}
              height={height}
            />
            <Title style={{ color: "white" }} margin={30} align="left">
              {title}
            </Title>

            {tooltip.enabled && <Tooltip shared useHTML {...tooltip.options} />}

            {legend.enabled && (
              <Legend
                itemStyle={{
                  color: "white",
                }}
                {...legend.options}
              ></Legend>
            )}

            <XAxis
              labels={{
                style: {
                  /* test - aggiunto questa chiave perche in alcuni chart la label troppo lunga prende troppo spazio */
                  whiteSpace: "nowrap",
                },
              }}
              categories={categories}
              type="category"
              scrollbar={{
                enabled: scrollbarEnabled,
                liveRedraw: true,
                barBackgroundColor: "#0D1626",
                barBorderRadius: 7,
                barBorderWidth: 0,
                buttonBackgroundColor: "#0D1626",
                buttonBorderWidth: 1,
                buttonBorderRadius: 7,
                buttonArrowColor: "#0D1626",
                buttonBorderColor: "#3f4961",
                rifleColor: "#0D1626",
                trackBackgroundColor: "#3f4961",
                trackBorderWidth: 1,
                trackBorderRadius: 8,
                trackBorderColor: "none",
              }}
              max={xAxisMax}
            ></XAxis>
            <YAxis
              labels={{ format: "{value} " + uom }}
              showEmpty
              title={{ ...yAxisTitleOptions, style: { color: "white" } }}
              type={showAsLogarithmic ? "logarithmic" : "linear"}
              plotLines={thresholds?.map((t) => ({
                value: t.value,
                color: t.color,
                width: 2,
                zIndex: 5,
                acrossPanes: true,
                dashStyle: "Dash",
                label: {
                  text: t.text,
                  align: "right",
                  x: -10,
                  style: {
                    color: t.color,
                  },
                },
              }))}
              {...yAxisOptions}
            >
              {series.map((serie, i) => (
                <ColumnSeries key={i} {...serie} borderColor="none" />
              ))}
            </YAxis>
          </HighchartsChart>
        </HighchartsCustomProvider>
        {showLogarithmicToggle && (
          <SentinelSwitch
            isChecked={showAsLogarithmic}
            onChange={handleChange}
            label={translate("view_logaritmic_scale")}
          />
        )}
      </>
    );
  }

  static DurationBarchart({
    title,
    categories,
    data,
    uom,
    showLogarithmicToggle = true,
  }: BarchartProps & { showLogarithmicToggle?: boolean }) {
    const [checked, setChecked] = useState(false);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
    };

    const translate = useTranslate();

    return (
      <>
        {showLogarithmicToggle && (
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={translate("view_logaritmic_scale")}
            />
          </FormGroup>
        )}

        <HighchartsCustomProvider>
          <HighchartsChart
            accessibility={{ enabled: false }}
            exporting={{
              enabled: true,
              buttons: {
                contextButton: {
                  menuItems: ["downloadCSV", "downloadXLS"],
                },
              },
            }}
          >
            <Chart
              backgroundColor={"transparent"}
              type="column"
              zooming={{ type: "x" }}
              style={{
                color: "white",
              }}
              alignTicks={false}
            />

            <Title align="left" style={{ color: "white" }}>
              {title}
            </Title>

            <Tooltip
              shared
              split={false}
              useHTML
              valueDecimals={2}
              formatter={function () {
                if (!this.x) return;
                const d = Duration.fromMillis(
                  parseInt(this.y!.toString(), 10) * 1000,
                );
                const date = d.toFormat("hh:mm:ss");

                return renderToString(
                  <div
                    style={{
                      width: "200px",
                      display: "flex",
                      flexDirection: "column",
                      gap: ".5rem",
                    }}
                  >
                    {this.points
                      ? this.points.map((point, i) => (
                          <div key={i}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: "&bull;",
                              }}
                              style={{
                                color: point.color?.toString(),
                              }}
                            />
                            <b> Duration</b>: {date}
                            <br />
                          </div>
                        ))
                      : null}
                  </div>,
                );
              }}
            />
            <XAxis categories={categories}></XAxis>
            <YAxis
              showEmpty
              type={checked ? "logarithmic" : "linear"}
              dateTimeLabelFormats={{
                second: "%H:%M",
                minute: "%H:%M",
                hour: "%H:%M",
                day: "%H:%M",
                week: "%H:%M",
                month: "%H:%M",
                year: "%H:%M",
              }}
              labels={{
                // style: { color: "white" },
                formatter: function () {
                  if (typeof this.value === "number") {
                    return (
                      Duration.fromMillis(this.value * 1000).toFormat(
                        "hh:mm:ss",
                      ) +
                      " " +
                      uom
                    );
                  }
                  return this.value;
                },
              }}
            >
              <ColumnSeries name="value" borderColor="none" data={data} />
            </YAxis>
          </HighchartsChart>
        </HighchartsCustomProvider>
      </>
    );
  }

  static MultiSeriesDurationBarchart({
    title,
    categories = [],
    data,
    uom = "",
  }: {
    title: string;
    categories?: string[];
    data: { name: string; value: number[]; color?: string }[];
    uom?: string;
  }) {
    return (
      <HighchartsCustomProvider>
        <HighchartsChart
          accessibility={{ enabled: false }}
          exporting={{
            enabled: true,
            buttons: {
              contextButton: {
                menuItems: ["downloadCSV", "downloadXLS"],
              },
            },
          }}
        >
          <Chart
            backgroundColor={"transparent"}
            type="column"
            zooming={{ type: "x" }}
            style={{
              color: "white",
            }}
            alignTicks={false}
          />

          <Title align="left" style={{ color: "white" }}>
            {title}
          </Title>

          <Tooltip
            shared
            split={false}
            useHTML
            valueDecimals={2}
            formatter={function () {
              if (!this.x) return;
              const d = Duration.fromMillis(
                parseInt(this.y!.toString(), 10) * 1000,
              );
              const date = d.toFormat("hh:mm:ss");

              return renderToString(
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: ".5rem",
                  }}
                >
                  {this.points
                    ? this.points.map((point, i) => (
                        <div key={i}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: "&bull;",
                            }}
                            style={{
                              color: point.color?.toString(),
                            }}
                          />
                          <b>{point.series.name}</b>: {date} hh:mm:ss
                          <br />
                        </div>
                      ))
                    : null}
                </div>,
              );
            }}
          />
          <Legend
            itemStyle={{
              color: "white",
            }}
            itemHoverStyle={{
              color: "#ccc",
            }}
            layout="vertical"
            align="right"
            verticalAlign="middle"
          />
          <XAxis categories={categories}></XAxis>
          <YAxis
            showEmpty
            type="linear"
            dateTimeLabelFormats={{
              second: "%H:%M",
              minute: "%H:%M",
              hour: "%H:%M",
              day: "%H:%M",
              week: "%H:%M",
              month: "%H:%M",
              year: "%H:%M",
            }}
            labels={{
              style: { color: "white" },
              formatter: function () {
                if (typeof this.value === "number") {
                  return (
                    Duration.fromMillis(this.value * 1000).toFormat(
                      "hh:mm:ss",
                    ) +
                    " " +
                    uom
                  );
                }
                return this.value;
              },
            }}
          >
            {data.map((series, i) => (
              <ColumnSeries
                key={i}
                name={series.name}
                borderColor="none"
                data={series.value}
                color={series.color}
              />
            ))}
          </YAxis>
        </HighchartsChart>
      </HighchartsCustomProvider>
    );
  }
  static GroupedDurationBarchart({
    title,
    categories,
    data,
    showLogarithmicToggle = true,
  }: Omit<BarchartProps, "data"> & {
    data: {
      name: string;
      data: number[];
    }[];
    showLogarithmicToggle?: boolean;
  }) {
    const [checked, setChecked] = useState(false);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
    };

    const translate = useTranslate();

    return (
      <>
        {showLogarithmicToggle && (
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={translate("view_logaritmic_scale")}
            />
          </FormGroup>
        )}{" "}
        <HighchartsCustomProvider>
          <HighchartsChart
            accessibility={{ enabled: false }}
            exporting={{
              enabled: true,
              buttons: {
                contextButton: {
                  menuItems: ["downloadCSV", "downloadXLS"],
                },
              },
            }}
          >
            <Chart
              backgroundColor={"transparent"}
              type="column"
              zooming={{ type: "x" }}
              style={{
                color: "white",
              }}
              alignTicks={false}
              legend={{ enabled: true }}
            />
            <Title align="left" style={{ color: "white" }}>
              {title}
            </Title>

            <Tooltip
              shared
              split={false}
              useHTML
              valueDecimals={2}
              formatter={function () {
                if (!this.x) return;

                return renderToString(
                  <div
                    style={{
                      width: "fit-content",
                      border: "none",
                      display: "flex",
                      flexDirection: "column",
                      gap: ".5rem",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>{this.x}</div>

                    {this.points
                      ? this.points.map((point, i) => {
                          const d = Duration.fromMillis(
                            parseInt(point.y!.toString(), 10) * 1000,
                          );
                          const date = d.toFormat("hh:mm:ss");
                          return (
                            <div key={i}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: "&bull;",
                                }}
                                style={{
                                  color: point.color?.toString(),
                                }}
                              />
                              <b> {point.series.name} Duration</b>: {date}
                              <br />
                            </div>
                          );
                        })
                      : null}
                  </div>,
                );
              }}
            />
            <XAxis categories={categories}></XAxis>
            <YAxis
              showEmpty
              type={checked ? "logarithmic" : "linear"}
              dateTimeLabelFormats={{
                second: "%H:%M",
                minute: "%H:%M",
                hour: "%H:%M",
                day: "%H:%M",
                week: "%H:%M",
                month: "%H:%M",
                year: "%H:%M",
              }}
              labels={{
                style: { color: "white" },
                formatter: function () {
                  if (typeof this.value === "number") {
                    return Duration.fromMillis(this.value * 1000).toFormat(
                      "hh:mm:ss",
                    );
                  }
                  return this.value;
                },
              }}
            >
              {data.map((d, i) => (
                <ColumnSeries
                  key={i}
                  name={d.name}
                  borderColor="none"
                  data={d.data}
                />
              ))}
            </YAxis>
          </HighchartsChart>
        </HighchartsCustomProvider>
      </>
    );
  }
}

/**
 * Still used for assetChart in lyo analytics, needs refactoring
 */
export const Barchart = ({
  title,
  categories,
  data,
  uom,
}: BarchartProps & { uom: string }) => {
  const dataWithColors = data.map((d, i) => ({ ...d, color: COLORS[i] }));
  return (
    <HighchartsCustomProvider>
      <HighchartsChart
        accessibility={{ enabled: false }}
        exporting={{
          enabled: true,
          buttons: {
            contextButton: {
              menuItems: ["downloadCSV", "downloadXLS"],
            },
          },
        }}
      >
        <Chart
          backgroundColor={"transparent"}
          type="column"
          zooming={{ type: "x" }}
          style={{
            color: "white",
          }}
          alignTicks={false}
        />
        <Title style={{ color: "white" }}>{title}</Title>

        <Tooltip
          shared
          formatter={function () {
            // const batchName = this.points ? this.points[0].key : "Missing name";

            return renderToString(
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem",
                }}
              >
                {this.points
                  ? this.points.map((point) => (
                      <div>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: "&bull;",
                          }}
                          style={{
                            color: point.color?.toString(),
                          }}
                        />
                        <b> {point.series.name}</b>:{" "}
                        <b>
                          {point.y?.toFixed(2)} {uom}
                        </b>
                        <br />
                      </div>
                    ))
                  : null}
              </div>,
            );
          }}
        />
        <XAxis categories={categories}></XAxis>
        <YAxis labels={{ format: "{value} " + uom }} type="linear">
          <ColumnSeries
            name="Amount"
            borderColor="none"
            data={dataWithColors}
          />
        </YAxis>
      </HighchartsChart>
    </HighchartsCustomProvider>
  );
};
