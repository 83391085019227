import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import {
  GenericsDataWithDynamicKeys,
  parseDownloadData,
} from "../utils/parse-cyclic-data";
import { Translations, useLanguage, useTranslate } from "@/i18n/config";

const DurationSchema = z
  .union([z.number(), z.string()])
  .transform((v) => (typeof v === "string" ? parseFloat(v) : v));

export type Duration = z.infer<typeof DurationSchema>;

export const RecipeSchema = z.object({
  Recipe: z.string(),
  Duration: DurationSchema,
});

export type Recipe = z.infer<typeof RecipeSchema>;

export const FormatSummarySchema = z.object({
  Recipe: z.array(RecipeSchema),
});
export type FormatSummary = z.infer<typeof FormatSummarySchema>;

export const AlarmStreamSchema = z.object({
  ErrorDescription: z.string(),
  FamilyDescription: z.string(), //! può essere undefined
  EfficiencyFamilyDescription: z.string(),
  Error: z.number(),
  Timestamp: z.number(),
  ZoneDescription: z.string(), // ZoneDescriptionSchema
});
export type AlarmStream = z.infer<typeof AlarmStreamSchema>;

export const ResultSchema = z.object({
  ErrorDescription: z.string(),
  MTTR: z.number(),
  CountPercentage: z.number(),
  Error: z.number(),
  Duration: z.number(),
  Count: z.number(),
  DurationPercentage: z.number(),
  MTBF: z.number(),
});

export type Result = z.infer<typeof ResultSchema>;

export const ArrayValueSchema = z.object({
  dateStart: z.number(),
  dateEnd: z.number(),
  value: z.number(),
});

export type ArrayValue = z.infer<typeof ArrayValueSchema>;

export const AlarmCountDurationSchema = z.object({
  Result: z.union([z.array(ResultSchema), z.record(z.string(), ResultSchema)]),
});

export type AlarmCountDuration = z.infer<typeof AlarmCountDurationSchema>;
const AlarmNoDataSchema = z.object({ Result: z.literal("No Data") });
export type AlarmNoData = z.infer<typeof AlarmNoDataSchema>;

const DataRowSchema = z.object({
  wasteCounter: z.number().optional(),
  formatSummary: FormatSummarySchema,
  alarmStream: z.array(AlarmStreamSchema),
  alarmCountDuration: z.union([AlarmCountDurationSchema, AlarmNoDataSchema]),
  hasAnomalies: z.boolean(),
  availability: z.number().optional(),
  counter: z.number().optional(),
  oee: z.number(),
  quality: z.number(),
  uptime: z.number(),
  wasteCounterLine: z.number().optional(),
  dataStartTimelapse: z.number(),
  performance: z.number().optional(),
  stateParametrics: z.record(z.string(), z.number()),
  wasteCounterInteraction: z.number().optional(),
  allTurnTime: z.number(),
  timelapse: z.string(),
});

export type DataRow = z.infer<typeof DataRowSchema>;

const DownloadResult = z.object({
  data: z.array(DataRowSchema),
  response: z.literal(true),
});
export type ColumnDefinition = {
  idName: string;
  displayName: string;
};
type DataResult = {
  processedDataTable: GenericsDataWithDynamicKeys[];
  columnsDefinition: ColumnDefinition[];
};

export type DownloadResult = z.infer<typeof DownloadResult>;
type DownloadResponse = ThingworxError | DownloadResult;

type Payload = {
  dateStart: DateTime;
  dateEnd: DateTime; //DateTime;
  machineName: string;
  timeSelection: string;
  language_id: string;
};

export const useGetDownloadData = ({ isEnabled }: { isEnabled: boolean }) => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const { dates, timeSelection } = useTimeSelection();
  const language = useLanguage();
  const translate = useTranslate();

  return useQuery<DataResult>({
    queryKey: [
      "download-data-cyclic",
      dates.dateStart,
      dates.dateEnd,
      machine?.machine,
      timeSelection,
      language,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, DownloadResponse>({
        appKey,
        payload: {
          dateStart: dates.dateStart,
          dateEnd: dates.dateEnd,
          machineName: machine!.machine,
          timeSelection,
          language_id: language,
        },
        url: "dig.c.download_thing/Services/getData",
      });
      if (!response.response) throw new Error(response.errorString);
      if (response.data.length === 0) {
        return {
          processedDataTable: parseDownloadData({ dataTable: [] }),
          columnsDefinition: [],
        };
      }
      const filteredResponse = getFilterResponse(response);
      const validatedResponse = DownloadResult.parse(filteredResponse);
      const machineStates = Object.keys(
        validatedResponse.data[0].stateParametrics,
      );
      const statesParametricsColDef = getMachineStatesColObj(machineStates);
      const columnsDefinition = getColumnDefinition({
        machineType: machine!.machineType,
        statesParametricsColDef,
        translate: translate,
      });
      return {
        processedDataTable: parseDownloadData({
          dataTable: validatedResponse.data,
        }),
        columnsDefinition,
      };
    },
    enabled: isEnabled,
  });
};

/**
 * User to filter the response from the api, removing the objects in the array with missing keys, which cause the page to break
 * @param response input from the api
 */
function getFilterResponse(response: DownloadResult): DownloadResult {
  const data = response.data.filter((item): item is DataRow => {
    const parsedResult = DataRowSchema.safeParse(item);
    return parsedResult.success;
  });
  return { ...response, data };
}

function getColumnDefinition({
  machineType,
  statesParametricsColDef,
  translate,
}: {
  machineType: string;
  statesParametricsColDef: ColumnDefinition[];
  translate: (name: Translations) => string;
}): ColumnDefinition[] {
  const commonColumns = [
    { idName: "timelapse", displayName: translate("reports.period") },
    { idName: "dataStartTimelapse", displayName: translate("date") },
    { idName: "allTurnTime", displayName: translate("duration") },
    { idName: "recipe", displayName: translate("machine.recipe") },
    { idName: "alarmDetails", displayName: translate("alarms") },
    { idName: "hasAnomalies", displayName: translate("anomalies.anomaly") },
    { idName: "uptime", displayName: translate("machine.running_time") },
    { idName: "oee", displayName: "OEE" },
    { idName: "quality", displayName: translate("kpi.quality") },
  ];

  return machineType === "cyclic"
    ? [
        ...commonColumns,
        { idName: "availability", displayName: translate("kpi.availability") },
        { idName: "performance", displayName: translate("kpi.performance") },
        { idName: "counter", displayName: translate("total_production") },
        {
          idName: "wasteCounter",
          displayName: translate("waste_counter_machine"),
        },
        {
          idName: "wasteCounterLine",
          displayName: translate("waste_counter_line"),
        },
        {
          idName: "wasteCounterInteraction",
          displayName: translate("waste_counter_interaction"),
        },
        ...statesParametricsColDef,
      ]
    : [...commonColumns, ...statesParametricsColDef];
}

function getMachineStatesColObj(machineStates: Array<string>) {
  const stateParametricsArray: ColumnDefinition[] = [];
  for (const key of machineStates) {
    const camelCaseKey = key.replace(
      /(?:^\w|[A-Z]|\b\w|\s+)/g,
      (match, index) => {
        if (+match === 0) return ""; // Remove leading digits
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
      },
    );

    stateParametricsArray.push({
      idName: camelCaseKey,
      displayName: key.toString(),
    });
  }
  return stateParametricsArray;
}
