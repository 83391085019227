import {
  LineSeries,
  Tooltip,
  SplineSeries,
  HighchartsChart,
  Chart,
  Title,
  XAxis,
  YAxis,
  Legend,
  AreaRangeSeries,
} from "react-jsx-highcharts";
import { HighchartsCustomProvider } from "../highcharts";

export class Arearange {
  static Custom({
    title,
    yAxis,
    tooltip = { enabled: true },
    legend = { enabled: false },
    lineType = "line",
    xAxisType = "datetime",
    xAxisOptions,
  }: {
    title: string;
    yAxis: {
      uom: string;
      options?: Highcharts.YAxisOptions;
      series: Highcharts.SeriesLineOptions;
      areaRangeSeries: Highcharts.SeriesArearangeOptions;
      yAxisTitleOptions?: Highcharts.YAxisTitleOptions;
      thresholds?: {
        value: number;
        color: string;
        text: string;
      }[];
    };
    legend?:
      | { enabled: false }
      | { enabled: true; options?: Partial<Highcharts.LegendOptions> };
    tooltip?:
      | { enabled: false }
      | { enabled: true; options?: Partial<Highcharts.TooltipOptions> };
    lineType?: "line" | "spline";
    xAxisType?: Highcharts.AxisTypeValue;
    xAxisOptions?: Highcharts.XAxisOptions;
  }) {
    const Line = lineType === "line" ? LineSeries : SplineSeries;
    return (
      <HighchartsCustomProvider>
        <HighchartsChart accessibility={{ enabled: false }} plotOptions={{}}>
          <Chart
            backgroundColor={"transparent"}
            type=""
            zooming={{ type: "xy" }}
            style={{
              color: "white",
            }}
          />
          <Title style={{ color: "white" }} align="left">
            {title}
          </Title>
          {tooltip.enabled && <Tooltip shared useHTML {...tooltip.options} />}
          {legend.enabled && (
            <Legend
              itemStyle={{
                color: "white",
              }}
              {...legend.options}
            ></Legend>
          )}
          <XAxis
            scrollbar={{
              enabled: true,
              liveRedraw: true,
              barBackgroundColor: "#0D1626",
              barBorderRadius: 7,
              barBorderWidth: 0,
              buttonBackgroundColor: "#0D1626",
              buttonBorderWidth: 1,
              buttonBorderRadius: 7,
              buttonArrowColor: "#0D1626",
              buttonBorderColor: "#3f4961",
              rifleColor: "#0D1626",
              trackBackgroundColor: "#3f4961",
              trackBorderWidth: 1,
              trackBorderRadius: 8,
              trackBorderColor: "none",
            }}
            type={xAxisType}
            {...xAxisOptions}
          ></XAxis>
          <YAxis
            labels={{
              style: { color: "white" },
              format: "{value} " + yAxis.uom,
            }}
            showEmpty
            title={yAxis.yAxisTitleOptions}
            type={"linear"}
            plotLines={yAxis.thresholds?.map((t) => ({
              value: t.value,
              color: t.color,
              width: 2,
              zIndex: 5,
              acrossPanes: true,
              dashStyle: "Dash",
              label: {
                text: t.text,
                align: "right",
                x: -10,
                style: {
                  color: t.color,
                },
              },
            }))}
            {...yAxis.options}
            alignTicks={false}
          >
            <Line {...yAxis.series} zIndex={1} borderColor="none" />
            <AreaRangeSeries
              {...yAxis.areaRangeSeries}
              linkedTo=":previous"
              borderColor="none"
            />
          </YAxis>
        </HighchartsChart>
      </HighchartsCustomProvider>
    );
  }
}
