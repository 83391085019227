import { ScatterPlot } from "@/components/highcharts/scatter/Scatter";
import { SelectTimeSelection } from "@/components/SelectTimeSelection";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { TableParser } from "@/utils/TableParser";
import { Stack, Typography } from "@mui/material";
import { AnalyticContainerSkeleton } from "../Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { BasicTable } from "@/components/BasicTable";
import {
  useGetAccessRequestDetail,
  AccessRequestDetail,
} from "./api/useGetAccessRequestDetail";
import {
  useGetAccessRequestOverview,
  AccessRequestOverview,
} from "./api/useGetAccessRequestOverview";
import { ShowEmptyData } from "@/components/ShowEmptyData";
import { useFormatTimestamp } from "@/utils/TimeHelpers";
import { Card } from "@/components/Layout/Card";
import { DataTransformer } from "@/utils/DataTransformer";
import { useTranslate } from "@/i18n/config";
import { renderToString } from "react-dom/server";

export const AccessRequest = () => {
  const { formatTimestamp } = useFormatTimestamp();
  const translate = useTranslate();
  const { data: carterOverviews, isLoading } = useGetAccessRequestOverview();

  const { data: carterDetails, isLoading: loadingCarterDetails } =
    useGetAccessRequestDetail();

  return (
    <>
      <MachineViewNavBar>
        <SelectTimeSelection />
      </MachineViewNavBar>
      <Stack gap={2}>
        {loadingCarterDetails ? (
          <>
            <AnalyticContainerSkeleton />
            <AnalyticContainerSkeleton />
          </>
        ) : null}
        {carterDetails ? (
          <>
            <Card>
              <ScatterPlot.Custom
                title={translate("access_requests")}
                scrollbarEnabled={false}
                tooltip={{
                  enabled: true,
                  options: {
                    formatter() {
                      const custom =
                        "custom" in this.point
                          ? (this.point.custom as {
                              description: string;
                            })
                          : { description: "" };
                      return renderToString(
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 5,
                          }}
                        >
                          <div>
                            {translate("description")}:{" "}
                            <b>{custom.description}</b>
                          </div>
                          {this.x && typeof this.x === "number" ? (
                            <div>
                              {translate("date")}:
                              <b>
                                {formatTimestamp({
                                  timestamp: this.x,
                                })}
                              </b>
                            </div>
                          ) : null}
                        </div>,
                      );
                    },
                  },
                }}
                yAxis={[
                  {
                    options: {
                      categories: carterDetails.categories,
                    },

                    uom: "",
                    series: [
                      {
                        type: "scatter",
                        color: "red",
                        turboThreshold: 0,
                        data: carterDetails.Result.map((carter) => ({
                          x: carter.Timestamp,
                          custom: {
                            description: carter.CarterDescription,
                          },
                          y: carterDetails.categories.findIndex(
                            (j) => j === carter.ZoneDescription,
                          ),
                        })),
                      },
                    ],
                  },
                ]}
              />
            </Card>
            <CarterDetailsTable carterDetails={carterDetails.Result} />
          </>
        ) : null}
        {isLoading ? <AnalyticContainerSkeleton /> : null}
        {carterOverviews ? (
          <CarterOverviewTable carterOverviews={carterOverviews} />
        ) : null}
      </Stack>
    </>
  );
};

function CarterDetailsTable({
  carterDetails,
}: {
  carterDetails: AccessRequestDetail[];
}) {
  const translate = useTranslate();
  const { formatTimestamp } = useFormatTimestamp();
  if (carterDetails.length === 0)
    return <ShowEmptyData title={translate("access_requests.detail")} />;

  const { columns, rows } = TableParser.parseData<AccessRequestDetail>({
    data: carterDetails,
    columnsMap: {
      Timestamp: translate("date"),
      CarterDescription: translate("description"),
      ZoneDescription: translate("zone"),
    },
    columnsOrder: ["Timestamp", "CarterDescription", "ZoneDescription"],
    dateFormatter: formatTimestamp,
  });

  return (
    <Card>
      <Typography variant="h5" mb={2}>
        {translate("access_requests.carter_detailed_table")}
      </Typography>
      <BasicTable
        columns={columns}
        rows={rows}
        fileName="carter_detailed_data"
      />
    </Card>
  );
}

function CarterOverviewTable({
  carterOverviews,
}: {
  carterOverviews: AccessRequestOverview[];
}) {
  const translate = useTranslate();
  const { formatTimestamp } = useFormatTimestamp();
  if (carterOverviews.length === 0)
    return <ShowEmptyData title={translate("access_requests.overview")} />;

  const { columns, rows } = TableParser.parseData<AccessRequestOverview>({
    data: carterOverviews.map((item) =>
      DataTransformer.dataTransformer({
        input: {
          ...item,
          Duration: item.Duration * 1000,
        },
        transform: {},
      }),
    ),
    columnsMap: {
      Count: translate("count"),
      Duration: translate("duration"),
      ZoneDescription: translate("zone"),
    },
    columnsOrder: ["Count", "Duration", "ZoneDescription"],
    dateFormatter: formatTimestamp,
  });

  return (
    <Card>
      <Typography variant="h5" mb={2}>
        {translate("access_requests.carter_overview_table")}
      </Typography>
      <BasicTable
        columns={columns}
        rows={rows}
        fileName="carted_overview_data"
      />
    </Card>
  );
}
