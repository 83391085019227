import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { useGetTopRejectCauses } from "../api/useGetTopRejectCauses";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { fileNameFactory } from "@/utils/fileNameFactory";
import { useMachineContext } from "@/context/machine-context";
import { renderToString } from "react-dom/server";

export const TopTenRejects = () => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetTopRejectCauses();

  const { machine } = useMachineContext();
  const machineName = machine?.machine;

  if (isLoading) {
    return <AnalyticContainerSkeleton />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  return (
    <Card>
      <BarChart.Custom
        filename={fileNameFactory({
          name: "top_ten_rejects",
          machine: machineName,
        })}
        categories={data?.map((value) => value.cause)}
        title={`Top 10 ${translate("reject_causes")}`}
        uom=""
        yAxisTitleOptions={{
          text: "Reject Causes Wastes",
          style: { color: "white" },
        }}
        tooltip={{
          enabled: true,
          options: {
            formatter: function () {
              /**
               * this is a ts hack to get the custom property working,
               * in this case they would be inside only the first point
               */
              const point = this.points?.[0].point as unknown as {
                name: string;
                custom: {
                  description: string;
                };
              };

              if (point) {
                return renderToString(
                  <div
                    style={{ display: "flex", flexDirection: "column", gap: 3 }}
                  >
                    <b>{point.name!}</b>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: "\u25CF",
                        }}
                        style={{ color: "#F7B500" }}
                      ></span>{" "}
                      {translate("waste_other")}: <b>{this.y}</b>
                    </span>
                    <span>
                      {translate("description")}:{" "}
                      <b>{point.custom.description}</b>
                    </span>
                  </div>,
                );
              }
            },
          },
        }}
        series={[
          {
            name: translate("reject_causes"),
            type: "column",
            color: "#F7B500",
            data: data?.map((value) => ({
              name: value.cause,
              y: value.occurences,
              custom: {
                description: value.description,
              },
            })),
          },
        ]}
      />
    </Card>
  );
};
