import Highcharts from "highcharts/highstock";

import {
  HighchartsChart,
  Chart,
  XAxis,
  Legend,
  Tooltip,
  YAxis,
  SolidGaugeSeries,
  Title,
} from "react-jsx-highcharts";
import { HighchartsCustomProvider } from "../highcharts";

const dataLabels = {
  format:
    '<div style="text-align:center"><span style="font-size:25px;color:black">{y}</span><br/><span style="font-size:12px;color:silver">km/h</span></div>',
  y: -50,
};
const tooltip = {
  valueSuffix: " km/h",
};

function getColor(title: string): string {
  switch (title) {
    case "OEE":
      return "#56BC72";
    case "Availability":
      return "#005D6E";
    case "Uptime":
      return "#666961";
    case "Performance":
      return "#007E77";
    case "Quality":
      return "#2F974B";
    case "Elapsed Time":
      return "#73BB6E";
    case "Inactive Time":
      return "#008190";
    default:
      throw new Error("bad status value");
  }
}

export const SolidGauge = ({
  title,
  min,
  max,
  value,
}: {
  title: string;
  min: number;
  max: number;
  value: number;
}) => {
  const mappedValue = {
    y: value,
    color: getColor(title),
  };

  return (
    <HighchartsCustomProvider>
      <HighchartsChart
        options={{
          chart: {
            type: "solidgauge",
            // height: "10vmax",
            backgroundColor: "transparent",
            style: {
              color: "white",
            },
          },
        }}
        accessibility={{ enabled: false }}
        plotOptions={{
          solidgauge: {
            dataLabels: {
              enabled: false,
            },
            lineWidth: 1,
            linecap: "round",
            stickyTracking: true,
            rounded: true,
          },
        }}
      >
        <Chart
          backgroundColor={"transparent"}
          type="solidgauge"
          style={{
            color: "white",
          }}
          alignTicks={false}
        />

        <XAxis />
        <YAxis lineWidth={0} min={min} max={max} tickPositions={[]}>
          <SolidGaugeSeries
            innerRadius="105%"
            radius="115%"
            name="Speed"
            data={[mappedValue]}
            dataLabels={dataLabels}
            tooltip={tooltip}
          />
        </YAxis>
      </HighchartsChart>
    </HighchartsCustomProvider>
  );
};

export class Solidgauge {
  static Custom({
    title,
    yAxis,
    tooltip = { enabled: true },
    legend = { enabled: false },
    xAxisOptions,
    plotOptions,
    chartOptions,
  }: {
    title: string;
    yAxis: {
      options?: Highcharts.YAxisOptions;
      series?: Highcharts.SeriesSolidgaugeOptions[];
      yAxisTitleOptions?: Highcharts.YAxisTitleOptions;
    };
    plotOptions?: Highcharts.PlotOptions;
    legend?:
      | { enabled: false }
      | { enabled: true; options?: Partial<Highcharts.LegendOptions> };
    tooltip?:
      | { enabled: false }
      | { enabled: true; options?: Partial<Highcharts.TooltipOptions> };
    xAxisOptions?: Highcharts.XAxisOptions;
    chartOptions?: Highcharts.ChartOptions;
  }) {
    return (
      <HighchartsCustomProvider>
        <HighchartsChart
          options={{
            chart: {
              type: "solidgauge",
              backgroundColor: "transparent",
              style: {
                color: "white",
              },
            },
          }}
          exporting={{
            enabled: false,
          }}
          accessibility={{ enabled: false }}
          plotOptions={{
            solidgauge: {
              dataLabels: {
                enabled: false,
              },

              lineWidth: 1,
              linecap: "round",
              stickyTracking: true,
              rounded: true,
            },
            ...plotOptions,
          }}
        >
          <Chart
            backgroundColor={"transparent"}
            type="solidgauge"
            style={{
              color: "white",
            }}
            alignTicks={false}
            {...chartOptions}
          />

          <Title style={{ color: "white" }} align="left">
            {title}
          </Title>

          {tooltip.enabled && <Tooltip shared useHTML {...tooltip.options} />}

          {legend.enabled && (
            <Legend
              itemStyle={{
                color: "white",
              }}
              {...legend.options}
            ></Legend>
          )}
          <XAxis
            scrollbar={{
              enabled: true,
              liveRedraw: true,
              barBackgroundColor: "#0D1626",
              barBorderRadius: 7,
              barBorderWidth: 0,
              buttonBackgroundColor: "#0D1626",
              buttonBorderWidth: 1,
              buttonBorderRadius: 7,
              buttonArrowColor: "#0D1626",
              buttonBorderColor: "#3f4961",
              rifleColor: "#0D1626",
              trackBackgroundColor: "#3f4961",
              trackBorderWidth: 1,
              trackBorderRadius: 8,
              trackBorderColor: "none",
            }}
            {...xAxisOptions}
          ></XAxis>

          <YAxis
            labels={{ format: "{value} " }}
            showEmpty
            title={yAxis.yAxisTitleOptions}
            type={"linear"}
            alignTicks={false}
            {...yAxis.options}
          >
            {yAxis.series?.map((serie, i) => (
              <SolidGaugeSeries key={i} {...serie} borderColor="none" />
            ))}
          </YAxis>
        </HighchartsChart>
      </HighchartsCustomProvider>
    );
  }
}
