import { TranslationKey } from "@/i18n/config";
import {
  Box,
  Dialog,
  Typography,
  Button,
  List,
  ListItem,
  Tabs,
  Tab,
  Fade,
  IconButton,
} from "@mui/material";
import { useState, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EventIcon from "@mui/icons-material/Event";
import LockIcon from "@mui/icons-material/Lock";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

export const ShiftConfiguratorGuide = ({
  open,
  onClose,
  translate,
}: {
  open: boolean;
  onClose: () => void;
  translate: (key: TranslationKey) => string;
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const handleClose = () => {
    onClose();
  };

  const guideContent = [
    {
      title: translate("shifts_configuration.guide_tab.calendar_views"),
      icon: <CalendarMonthIcon />,
      content: [
        translate("shifts_configuration.guide_calendar.monthly_view"),
        translate("shifts_configuration.guide_calendar.weekly_view"),
      ],
    },
    {
      title: translate("shifts_configuration.guide_tab.default_shifts"),
      icon: <EventIcon />,
      content: [
        translate("shifts_configuration.guide_defaults.intro"),
        translate("shifts_configuration.guide_defaults.morning"),
        translate("shifts_configuration.guide_defaults.afternoon"),
        translate("shifts_configuration.guide_defaults.night"),
      ],
    },
    {
      title: translate("shifts_configuration.guide_tab.time_display"),
      icon: <AccessTimeIcon />,
      content: [
        translate("shifts_configuration.guide_time.display"),
        translate("shifts_configuration.guide_time.settings"),
      ],
    },
    {
      title: translate("shifts_configuration.guide_tab.editing_rules"),
      icon: <LockIcon />,
      content: [
        translate("shifts_configuration.guide_rules.future_only"),
        translate("shifts_configuration.guide_rules.limits"),
        translate("shifts_configuration.guide_rules.create"),
        translate("shifts_configuration.guide_rules.edit_show_8_days"),
        translate("shifts_configuration.guide_rules.lock"),
      ],
    },
    {
      title: translate("shifts_configuration.guide_tab.week_template"),
      icon: <ContentCopyIcon />,
      content: [
        translate("shifts_configuration.guide_template.intro"),
        translate("shifts_configuration.guide_template.step1"),
        translate("shifts_configuration.guide_template.step2"),
        translate("shifts_configuration.guide_template.step3"),
        translate("shifts_configuration.guide_template.note"),
      ],
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      keepMounted={false}
      PaperProps={{
        sx: {
          height: 600,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        },
      }}
    >
      <Box
        sx={{
          p: 4,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {/* Header - Fixed */}
        <Box sx={{ flexShrink: 0, position: "relative" }}>
          <IconButton
            ref={closeButtonRef}
            sx={{
              position: "absolute",
              right: -12,
              top: -12,
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="h5" mb={3} component="h2">
            {translate("shifts_configuration.guide_title")}
          </Typography>

          <Tabs
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue)}
            variant="fullWidth"
            aria-label="Guide sections"
          >
            {guideContent.map((section, index) => (
              <Tab
                key={section.title}
                icon={section.icon}
                label={section.title}
                id={`tab-${index}`}
                aria-controls={`tabpanel-${index}`}
                sx={{
                  minHeight: 90,
                  textTransform: "none",
                  "& .MuiTab-iconWrapper": {
                    marginBottom: "8px",
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                  "& .MuiTab-labelIcon": {
                    minHeight: "90px",
                    paddingTop: "12px",
                  },
                  "& .MuiTab-wrapper": {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  },
                  "& .MuiTab-root": {
                    padding: "12px 16px",
                  },
                }}
              />
            ))}
          </Tabs>
        </Box>

        {/* Content - Scrollable */}
        <Box
          sx={{
            flex: 1,
            overflow: "auto",
            my: 2,
          }}
        >
          {guideContent.map((section, index) => (
            <Fade
              key={section.title}
              in={activeTab === index}
              style={{
                display: activeTab === index ? "block" : "none",
                transitionDelay: activeTab === index ? "100ms" : "0ms",
              }}
            >
              <Box role="tabpanel" id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}>
                <List>
                  {section.content.map((text, i) => (
                    <ListItem key={i} sx={{ py: 1 }}>
                      <Typography>{text}</Typography>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Fade>
          ))}
        </Box>

        {/* Footer - Fixed */}
        <Box display="flex" justifyContent="flex-end" mt={2} sx={{ flexShrink: 0 }}>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              textTransform: "none",
              minWidth: 100,
            }}
          >
            {translate("acceptance_test.close")}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
