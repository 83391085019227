import { useState } from "react";
import { toast } from "react-hot-toast";
import { z } from "zod";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Collapse,
  List,
  Typography,
  styled,
} from "@mui/material";
import { XrangePointOptionsObject } from "highcharts/highstock";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import {
  ExpandLess,
  ExpandMore,
  ExpandMoreOutlined,
} from "@mui/icons-material";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { ONE_MINUTE, ONE_HOUR } from "@/utils/durationsInMilliseconds";
import { useGetBatchesAndRecipes } from "../../../api/Common/useGetBatchesAndRecipes";
import { SelectedBatch } from "./SelectedBatch";
import { Batch, BatchEvaluation, batchEvaluation, phase } from "../../../types";
import { XRangeChart } from "@/components/highcharts/xrange/XRange";
import { TimeHelpers, useFormatTimestamp } from "@/utils/TimeHelpers";
// import { Tracker } from "./Tracker";
import { ListWithShowMore } from "./ListWithShowMore";
import { colors } from "@/styles/colors";
import { useIsolatorContext } from "../../../context/useIsolatorContext";
import { useIsolatorDispatchContext } from "../../../context/useIsolatorDispatchContext";
import { EVALUATION_COLORS } from "../../../utils/evaluationColors";
import {
  FILTER_COLOR,
  MAX_BATCHES_NUMBER,
} from "../../../context/isolator-context";
import { readablePhase } from "../../../utils/readablePhase";
import { PHASE_ORDER } from "../../../utils/phaseOrder";

const THIRTY_MINUTES = ONE_MINUTE * 30;

const parseData = ({
  data,
  selectedBatches,
  filterByEvaluation,
  filterByRecipe,
  filterByDuration,
}: {
  data: Batch[];
  selectedBatches: Batch[];
  filterByEvaluation: BatchEvaluation | "all";
  filterByRecipe: string | "all";
  filterByDuration: "1h" | "30m" | "all";
}): XrangePointOptionsObject[] => {
  const filteredDataByEvaluation =
    filterByEvaluation === "all"
      ? data
      : data.filter((entry) => entry.status === filterByEvaluation);

  const filteredDataByRecipe =
    filterByRecipe === "all"
      ? filteredDataByEvaluation
      : filteredDataByEvaluation.filter(
          (entry) => entry.recipeName === filterByRecipe,
        );

  const filteredData =
    filterByDuration === "all"
      ? filteredDataByRecipe
      : filterByDuration === "30m"
      ? filteredDataByRecipe.filter(
          (entry) => entry.end - entry.start > THIRTY_MINUTES,
        )
      : filteredDataByRecipe.filter(
          (entry) => entry.end - entry.start > ONE_HOUR,
        );

  return filteredData.map((occurrence) => {
    const { name, start, end, id, recipeName, status, phases } = occurrence;
    const isSelected = selectedBatches.find((batch) => batch.id === id);
    const color =
      EVALUATION_COLORS[status][isSelected ? "selected" : "notSelected"];

    return {
      x: start,
      x2: end,
      y: 0,
      color,
      name: name,
      custom: {
        recipeName,
        status,
        phases,
      },
    };
  });
};

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  minHeight: "36.5px",
  maxHeight: "36.5px",
  backgroundColor: colors.palette.darkBlue,
  border: "1px solid #a5a5a5",
  "&.Mui-expanded": {
    border: `1px solid ${FILTER_COLOR}`,
    borderBottom: "1px solid transparent",
  },
  ":hover": {
    border: `1px solid ${FILTER_COLOR}`,
  },
}));

export const BatchSelection = ({
  enableSelectAll,
}: {
  enableSelectAll: boolean;
}) => {
  const translate = useTranslate();
  const { formatTimestamp } = useFormatTimestamp();
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [expand, setExpand] = useState(true);
  const [filterByEvaluation, setFilterByEvaluation] = useState<
    BatchEvaluation | "all"
  >("all");
  const [filterByRecipe, setFilterByRecipe] = useState<string | "all">("all");
  const [filterByDuration, setFilterByDuration] = useState<
    "30m" | "1h" | "all"
  >("all");
  const { batches } = useIsolatorContext();
  const dispatch = useIsolatorDispatchContext();
  const { data: apiData, error, isLoading } = useGetBatchesAndRecipes();

  if (isLoading) return <SkeletonCard height={180} />;
  if (error) return <Card>{translate("user_feedback.an_error_occurred")}</Card>;
  if (!apiData) return <Card>{translate("user_feedback.no_data")}</Card>;

  const data = parseData({
    data: apiData.batches,
    selectedBatches: batches,
    filterByEvaluation,
    filterByRecipe,
    filterByDuration,
  });
  const batchEvaluations: BatchEvaluation[] = [];
  apiData.batches.forEach(
    (batch) =>
      !batchEvaluations.includes(batch.status) &&
      batchEvaluations.push(batch.status),
  );

  const onClickBatch = ({ start, end }: { start: number; end: number }) => {
    const clonedApiData = [...apiData.batches];
    const selectedBatch = clonedApiData.find(
      (batch) => batch.start === start && batch.end === end,
    );
    if (selectedBatch) {
      const isAlreadySelected =
        batches.findIndex((batch) => batch.id === selectedBatch.id) > -1;
      if (isAlreadySelected) {
        dispatch({ type: "REMOVE_BATCH", item: selectedBatch });
      } else {
        if (batches.length >= MAX_BATCHES_NUMBER) {
          toast.error(
            translate("user_feedback.max_n_selected", {
              max: MAX_BATCHES_NUMBER,
            }),
          );
        } else {
          dispatch({ type: "ADD_BATCH", item: selectedBatch });
        }
      }
    }
  };

  const handleExpandPanel =
    (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  // let timer: NodeJS.Timeout;

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        zIndex: 1,
      }}
      initial={{ opacity: 0.5, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0 }}
    >
      <Box display="flex" justifyContent="space-between">
        <Box sx={{ p: ".5rem", display: "flex", gap: 1, alignItems: "center" }}>
          {enableSelectAll && (
            <Button
              variant="outlined"
              onClick={() => {
                const clonedData = [...apiData.batches];
                const filteredDataByRecipe =
                  filterByRecipe === "all"
                    ? clonedData
                    : clonedData.filter(
                        (entry) => entry.recipeName === filterByRecipe,
                      );

                const filteredData =
                  filterByDuration === "all"
                    ? filteredDataByRecipe
                    : filterByDuration === "30m"
                    ? filteredDataByRecipe.filter(
                        (entry) => entry.end - entry.start > THIRTY_MINUTES,
                      )
                    : filteredDataByRecipe.filter(
                        (entry) => entry.end - entry.start > ONE_HOUR,
                      );
                dispatch({
                  type: "UPDATE_BATCHES",
                  item: filteredData,
                });
              }}
            >
              {translate("actions.select_all")}
            </Button>
          )}
          <Button
            variant="outlined"
            onClick={() => dispatch({ type: "CLEAR_BATCHES" })}
          >
            {translate("actions.clear_all")}
          </Button>
          <Accordion
            disableGutters
            expanded={expanded === "status-panel"}
            onChange={handleExpandPanel("status-panel")}
          >
            <StyledAccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="status-panel-content"
              id="status-panel-header"
            >
              {translate("status")}
              {expanded === "status-panel" && (
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: 2,
                    bottom: "-5px",
                    left: "-1px",
                    boxSizing: "content-box",
                    width: "100%",
                    height: "5px",
                    borderRight: `1px solid ${FILTER_COLOR}`,
                    borderLeft: `1px solid ${FILTER_COLOR}`,
                    background: colors.palette.darkBlue,
                  }}
                ></Box>
              )}
            </StyledAccordionSummary>
            <AccordionDetails
              sx={{
                position: "absolute",
                zIndex: 1,
                backgroundColor: colors.palette.darkBlue,
                minWidth: 360,
                border: `1px solid ${FILTER_COLOR}`,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                p: "1rem",
                gap: 1,
                marginTop: "3px",
              }}
            >
              {batchEvaluations.map((evaluation, i) => {
                return (
                  <Button
                    key={evaluation + i}
                    sx={{
                      minWidth: 160,
                      color:
                        filterByEvaluation === evaluation ? FILTER_COLOR : null,
                      borderColor:
                        filterByEvaluation === evaluation ? FILTER_COLOR : null,
                    }}
                    variant="outlined"
                    onClick={() => {
                      setFilterByEvaluation(evaluation);
                    }}
                  >
                    {evaluation}
                  </Button>
                );
              })}
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            expanded={expanded === "recipe-panel"}
            onChange={handleExpandPanel("recipe-panel")}
          >
            <StyledAccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="recipe-panel-content"
              id="recipe-panel-header"
            >
              {translate("machine.recipe")}
              {expanded === "recipe-panel" && (
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: 2,
                    bottom: "-5px",
                    left: "-1px",
                    boxSizing: "content-box",
                    width: "100%",
                    height: "5px",
                    borderRight: `1px solid ${FILTER_COLOR}`,
                    borderLeft: `1px solid ${FILTER_COLOR}`,
                    background: colors.palette.darkBlue,
                  }}
                ></Box>
              )}
            </StyledAccordionSummary>
            <AccordionDetails
              sx={{
                position: "absolute",
                zIndex: 1,
                backgroundColor: colors.palette.darkBlue,
                minWidth: 360,
                border: `1px solid ${FILTER_COLOR}`,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                p: "1rem",
                gap: 1,
                marginTop: "3px",
              }}
            >
              {apiData.recipes.map((value) => (
                <Button
                  key={value}
                  sx={{
                    minWidth: 160,
                    color: filterByRecipe === value ? FILTER_COLOR : null,
                    borderColor: filterByRecipe === value ? FILTER_COLOR : null,
                  }}
                  variant="outlined"
                  onClick={() => {
                    setFilterByRecipe(value);
                  }}
                >
                  {value}
                </Button>
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion
            disableGutters
            expanded={expanded === "duration-panel"}
            onChange={handleExpandPanel("duration-panel")}
          >
            <StyledAccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="duration-panel-content"
              id="duration-panel-header"
            >
              {translate("duration")}
              {expanded === "duration-panel" && (
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: 2,
                    bottom: "-5px",
                    left: "-1px",
                    boxSizing: "content-box",
                    width: "100%",
                    height: "5px",
                    borderRight: `1px solid ${FILTER_COLOR}`,
                    borderLeft: `1px solid ${FILTER_COLOR}`,
                    background: colors.palette.darkBlue,
                  }}
                ></Box>
              )}
            </StyledAccordionSummary>
            <AccordionDetails
              sx={{
                position: "absolute",
                zIndex: 1,
                backgroundColor: colors.palette.darkBlue,
                minWidth: 360,
                border: `1px solid ${FILTER_COLOR}`,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                p: "1rem",
                gap: 1,
                marginTop: "3px",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  width: 120,
                  color: filterByDuration === "30m" ? FILTER_COLOR : null,
                  borderColor: filterByDuration === "30m" ? FILTER_COLOR : null,
                }}
                onClick={() => {
                  setFilterByDuration("30m");
                }}
              >
                {`> 30 ${translate("durations.minute_other")}`}
              </Button>
              <Button
                variant="outlined"
                sx={{
                  width: 120,
                  color: filterByDuration === "1h" ? FILTER_COLOR : null,
                  borderColor: filterByDuration === "1h" ? FILTER_COLOR : null,
                }}
                onClick={() => {
                  setFilterByDuration("1h");
                }}
              >
                {`> 1 ${translate("durations.hour")}`}
              </Button>
            </AccordionDetails>
          </Accordion>
          {filterByEvaluation !== "all" && (
            <Chip
              label={filterByEvaluation}
              onDelete={() => {
                setFilterByEvaluation("all");
              }}
            />
          )}
          {filterByRecipe !== "all" && (
            <Chip
              label={filterByRecipe}
              onDelete={() => {
                setFilterByRecipe("all");
              }}
            />
          )}
          {filterByDuration !== "all" && (
            <Chip
              label={filterByDuration}
              onDelete={() => {
                setFilterByDuration("all");
              }}
            />
          )}
        </Box>

        <Button onClick={() => setExpand(!expand)}>
          <Typography>{translate("select_batch_other")}</Typography>
          {expand ? <ExpandLess /> : <ExpandMore />}
        </Button>
      </Box>
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <Box sx={{ display: "flex", height: 120 }}>
          <Box sx={{ flex: 4 }}>
            {/* <Tracker totalBatches={apiData.batches} /> */}
            <XRangeChart
              customHeight={100}
              allowExporting={false}
              categories={[translate("analytics.batch")]}
              title=""
              data={data}
              tooltipFormatter={function () {
                const parseResult = z
                  .object({
                    x: z.number(),
                    x2: z.number(),
                    color: z.string(),
                    key: z.string(),
                    point: z.object({
                      custom: z.object({
                        recipeName: z.string(),
                        status: batchEvaluation,
                        phases: z.array(phase),
                      }),
                    }),
                  })
                  .safeParse(this);
                if (!parseResult.success) return;

                const {
                  color,
                  key,
                  x,
                  x2,
                  point: {
                    custom: { status, recipeName, phases },
                  },
                } = parseResult.data;

                const dateStart = formatTimestamp({
                  timestamp: x,
                });
                const dateEnd = formatTimestamp({
                  timestamp: x2,
                });
                const durationString = TimeHelpers.parseDurationToString({
                  duration: x2 - x,
                });
                let htmlPhaseList = "";
                phases.sort(
                  (a, b) => PHASE_ORDER.indexOf(a) - PHASE_ORDER.indexOf(b),
                );
                phases.forEach((phase, i) => {
                  if (i > 0) {
                    htmlPhaseList += "</b>, <b>" + readablePhase(phase);
                  } else {
                    htmlPhaseList += readablePhase(phase);
                  }
                });
                const category = key;
                return `<span style="color:${color}">●</span> <b>${category}</b><br>
                    ${translate("machine.recipe")}: <b>${recipeName}</b><br>
                    ${translate("start")}: <b>${dateStart}</b><br>
                    ${translate("end")}: <b>${dateEnd}</b><br>
                    ${translate("duration")}: <b>${durationString}</b><br>
                    Status: <b>${status}</b><br>
                    ${
                      phases.length > 0
                        ? translate("isolator.phase_other") +
                          ": <b>" +
                          htmlPhaseList +
                          "</b>"
                        : ""
                    }
                  `;
              }}
              seriesOptions={{
                cursor: "pointer",
                events: {
                  click: function (event) {
                    const point: XrangePointOptionsObject = event.point;
                    onClickBatch({ start: point.x!, end: point.x2! });
                  },
                },
              }}
            />
          </Box>
          <List
            sx={{
              maxWidth: 300,
              flex: 1,
              overflowY: "auto",
              p: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            component="nav"
          >
            {batches.length === 0 ? (
              <Typography p="1rem" variant="caption" color="#ccc">
                {translate("isolator.no_batch_selected")}
              </Typography>
            ) : batches.length <= 2 ? (
              batches.map((batch) => (
                <SelectedBatch key={batch.id} batch={batch} />
              ))
            ) : (
              <ListWithShowMore batches={batches} />
            )}
          </List>
        </Box>
      </Collapse>
    </Card>
  );
};
