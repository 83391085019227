import { create } from "zustand";

export type TimeFormat = "24h" | "12h";
export type DateFormat = "en-GB" | "en-US";

export type DateTimeFormatStore = {
  dateFormat: "en-GB" | "en-US";
  timeFormat: TimeFormat;
  setDateFormat: (format: DateFormat) => void;
  setTimeFormat: (timeFormat: TimeFormat) => void;
  resetDateFormat: () => void;
};

export const dateTimeFormatStore = create<DateTimeFormatStore>()((set) => ({
  dateFormat: (localStorage.getItem("dateFormat") as DateFormat) || "en-GB",
  timeFormat: (localStorage.getItem("timeFormat") as TimeFormat) || "24h",
  setDateFormat() {
    set((state) => {
      const newDateFormat = state.dateFormat === "en-GB" ? "en-US" : "en-GB";
      localStorage.setItem("dateFormat", newDateFormat);
      return { dateFormat: newDateFormat };
    });
  },
  setTimeFormat() {
    set((state) => {
      const newTimeFormat = state.timeFormat === "24h" ? "12h" : "24h";
      localStorage.setItem("timeFormat", newTimeFormat);
      return { timeFormat: newTimeFormat };
    });
  },
  resetDateFormat() {
    localStorage.setItem("dateFormat", "en-GB");
    set({ dateFormat: "en-GB" });
  },
}));
