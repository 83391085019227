const SERVER_URL = import.meta.env.VITE_SERVER_URL ?? "http://localhost:3000";
const SERVER_ENUMERATOR = import.meta.env.VITE_SERVER_ENUMERATOR ?? 0;
const INTELLECTA_ENDPOINT = import.meta.env.VITE_INTELLECTA_ENDPOINT;

const environment: "development" | "test" | "staging" | "production" =
  SERVER_ENUMERATOR === "0"
    ? "development"
    : SERVER_ENUMERATOR === "1"
    ? "test"
    : SERVER_ENUMERATOR === "2"
    ? "staging"
    : "production";

export { SERVER_ENUMERATOR, SERVER_URL, environment, INTELLECTA_ENDPOINT };
