/* eslint-disable @typescript-eslint/ban-ts-comment */
import { HighchartsCustomProvider } from "../highcharts";
import {
  HighchartsChart,
  Title,
  YAxis,
  Chart,
  Tooltip,
  PieSeries,
  VariablePieSeries,
  HighchartsChartProps,
  Legend,
} from "react-jsx-highcharts";
import { useMediaQuery } from "@mui/material";


interface PiechartProps {
  title: string;
  data: { name: string; y: number; color?: string; z?: number }[];
  label?: string;
  version: keyof typeof PiechartConfig;
}

type Helper = <T>(arg: { [K in keyof T]: Highcharts.Options }) => {
  [K in keyof T]: Highcharts.Options;
};

const asElementTypes: Helper = (arg) => arg;

const PiechartConfig = asElementTypes({
  machineDetail: {
    title: {
      text: "",
    },

    tooltip: {
      headerFormat: "",
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span><br/>' +
        " <b> {point.name}</b>: <b>{point.y}%</b><br/>",
    },

    plotOptions: {
      series: {
        animation: {
          duration: 1500,
        },
        shadow: {
          color: "#000000",
        },
      },
      variablepie: {
        tooltip: {
          headerFormat: "",
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span><br/>' +
            " <b> {point.name}</b>: <b>{point.y}%</b><br/>",
        },

        minPointSize: 10,
        innerSize: "35%",
        zMin: 0,
        startAngle: 180,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        borderColor: "#ff000000",
      },
    },
  },
  lyo: {
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
      series: {
        shadow: {
          color: "#000000",
          opacity: 0.1,
          width: 10,
          offsetX: 5,
          offsetY: 5,
        },
        minPointSize: 10,
        innerSize: "35%",
        zMin: 0,
        // @ts-ignore
        dataLabels: {
          // enabled: false,
          style: {
            color: "white",
            textOutline: 0,
          },
        },
      },
    },
  },
});

export const Piechart = ({
  title,
  data,
  label,
  version = "machineDetail",
}: PiechartProps) => {
  const desktop = useMediaQuery("(min-width:600px)");

  return (
    <HighchartsCustomProvider>
      <HighchartsChart
        plotOptions={PiechartConfig[version].plotOptions}
        accessibility={{ enabled: false }}
        exporting={{
          enabled: false,
        }}
        chart={{
          height: desktop ? "55%" : "45%",
        }}
      >
        <Chart
          backgroundColor={"transparent"}
          type="variablepie"
          style={{
            color: "white",
          }}
          alignTicks={false}
          height={desktop ? "55%" : "45%"}
          margin={[0, 0, 0, 0]}
        />

        <Title style={{ color: "white" }} align="left">
          {title}
        </Title>
        <Tooltip shared />

        <YAxis>
          {version === "lyo" ? (
            <PieSeries borderColor="none" name={label} data={data} />
          ) : (
            <VariablePieSeries borderColor="none" name={label} data={data} />
          )}
        </YAxis>
      </HighchartsChart>
    </HighchartsCustomProvider>
  );
};

export class PieChart {
  static Custom({
    title,
    seriesOptions,
    highchartsChartOptions,
    legendOptions,
    chartOptions,
    titleOptions,
    tooltipOptions,
  }: {
    title: string;
    seriesOptions: Highcharts.SeriesPieOptions;
    highchartsChartOptions?: HighchartsChartProps;
    legendOptions?: Highcharts.LegendOptions;
    chartOptions?: Highcharts.ChartOptions;
    titleOptions?: Highcharts.TitleOptions;
    tooltipOptions?: Highcharts.TooltipOptions;
  }) {
    return (
      <HighchartsCustomProvider>
        <HighchartsChart
          plotOptions={{
            pie: {
              dataLabels: {
                enabled: false,
              },
              showInLegend: true,
            },
          }}
          accessibility={{ enabled: false }}
          exporting={{
            enabled: false,
          }}
          {...highchartsChartOptions}
        >
          <Legend {...legendOptions} />
          <Chart
            backgroundColor={"transparent"}
            type="pie"
            style={{
              color: "white",
            }}
            {...chartOptions}
          />
          <Title style={{ color: "white" }} align="left" {...titleOptions}>
            {title}
          </Title>
          <Tooltip
            formatter={function () {
              return `<span style='color: ${this.color}'>● </span>${this.key}: <b>${this.y}%</b>`;
            }}
            {...tooltipOptions}
          />
          <YAxis>
            <PieSeries borderColor="none" {...seriesOptions} />
          </YAxis>
        </HighchartsChart>
      </HighchartsCustomProvider>
    );
  }
}
