import { HighchartsCustomProvider } from "../highcharts";

import {
  HighchartsChart,
  Chart,
  XAxis,
  YAxis,
  SplineSeries,
  Tooltip,
  LineSeries,
  Legend,
  Title,
  AreaRangeSeries,
  ScatterSeries,
  Credits,
  HighchartsChartProps,
} from "react-jsx-highcharts";

interface LineAndAreaRangeProps {
  title: string;
  yAxis: {
    uom: string;
    options?: Highcharts.YAxisOptions;
    series?: (
      | Highcharts.SeriesLineOptions
      | Highcharts.SeriesArearangeOptions
      | Highcharts.SeriesScatterOptions
    )[];
    yAxisTitleOptions?: Highcharts.YAxisTitleOptions;
    thresholds?: {
      value: number;
      color: string;
      text: string;
    }[];
  }[];
  highchartsChartOptions?: HighchartsChartProps;
  chartOptions?: Highcharts.ChartOptions;
  legend?:
    | { enabled: false }
    | { enabled: true; options?: Partial<Highcharts.LegendOptions> };
  tooltip?:
    | { enabled: false }
    | { enabled: true; options?: Partial<Highcharts.TooltipOptions> };
  lineType?: "line" | "spline";
  xAxisType?: Highcharts.AxisTypeValue;
  xAxisOptions?: Highcharts.XAxisOptions[];
  showMarkerOnEachEntry?: boolean;
  customCredits?: string;
}

export const LineAndAreaRange = ({
  title,
  yAxis = [],
  chartOptions,
  highchartsChartOptions,
  tooltip = { enabled: true },
  legend = { enabled: false },
  lineType = "line",
  xAxisType = "datetime",
  xAxisOptions,
  showMarkerOnEachEntry = true,
  customCredits,
}: LineAndAreaRangeProps) => {
  const Line = lineType === "line" ? LineSeries : SplineSeries;
  return (
    <HighchartsCustomProvider>
      <HighchartsChart
        accessibility={{ enabled: false }}
        {...highchartsChartOptions}
      >
        <Chart
          backgroundColor={"transparent"}
          type=""
          zooming={{ type: "xy" }}
          style={{
            color: "white",
          }}
          {...chartOptions}
        />
        <Title style={{ color: "white" }} align="left">
          {title}
        </Title>

        {tooltip.enabled && <Tooltip shared useHTML {...tooltip.options} />}

        {legend.enabled && (
          <Legend
            itemStyle={{
              color: "white",
            }}
            {...legend.options}
          ></Legend>
        )}
        {customCredits && (
          <Credits
            href="javascript:;"
            style={{ cursor: "default", fontSize: "11px" }}
          >
            {customCredits}
          </Credits>
        )}
        {xAxisOptions ? (
          xAxisOptions.map((options, i) => (
            <XAxis
              key={"x-axis-" + i}
              scrollbar={{
                enabled: false,
              }}
              {...options}
            ></XAxis>
          ))
        ) : (
          <XAxis
            scrollbar={{
              enabled: false,
            }}
            type={xAxisType}
          ></XAxis>
        )}
        {yAxis?.map(
          (
            { uom, series = [], thresholds = [], yAxisTitleOptions, options },
            i,
          ) => (
            <YAxis
              key={i}
              labels={{ format: "{value} " + uom }}
              showEmpty
              title={yAxisTitleOptions}
              type={"linear"}
              plotLines={thresholds?.map((t) => ({
                value: t.value,
                color: t.color,
                width: 2,
                zIndex: 5,
                acrossPanes: true,
                dashStyle: "Dash",
                label: {
                  text: t.text,
                  align: "right",
                  x: -10,
                  style: {
                    color: t.color,
                  },
                },
              }))}
              {...options}
              alignTicks={false}
            >
              {series.map((serie, i) => {
                if (serie.type === "line") {
                  return (
                    <Line
                      marker={
                        showMarkerOnEachEntry
                          ? {
                              enabled: showMarkerOnEachEntry,
                              radius: 2,
                              fillColor: "#0ee664",
                            }
                          : { enabled: false }
                      }
                      key={i}
                      {...serie}
                      borderColor="none"
                      zIndex={2}
                    />
                  );
                } else if (serie.type === "arearange") {
                  return <AreaRangeSeries key={i} {...serie} />;
                } else {
                  return <ScatterSeries key={i} {...serie} zIndex={3} />;
                }
              })}
            </YAxis>
          ),
        )}
      </HighchartsChart>
    </HighchartsCustomProvider>
  );
};
