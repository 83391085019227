import { TranslationKey } from "@/i18n/config";
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { useEditWeekStartsOn } from "../api/useEditWeekStartsOn";

export function WeekStartsOnSelect({
  weekStartsOn,
  translate,
  enabled,
}: {
  weekStartsOn: number;
  translate: (key: TranslationKey) => string;
  enabled: boolean;
}) {
  const { mutate: setNewWeekStartsOn } = useEditWeekStartsOn();

  const handleEditWekStartsOn = (event: SelectChangeEvent) => {
    const weekStartsOnNew = event.target.value as string;
    setNewWeekStartsOn(Number(weekStartsOnNew));
  };

  return (
    <FormControl>
      <InputLabel id="first-day-select-label">{translate("first_day")}</InputLabel>
      <Select
        disabled={!enabled}
        labelId="first-day-select-label"
        id="first-day-select-label"
        value={weekStartsOn.toString()}
        label={translate("first_day")}
        onChange={handleEditWekStartsOn}
        sx={{ fontWeight: "bold", width: "10rem", height: "2.8rem" }}
      >
        <MenuItem value={0}>{translate("days.sunday")}</MenuItem>
        <MenuItem value={1}>{translate("days.monday")}</MenuItem>
        <MenuItem value={2}>{translate("days.tuesday")}</MenuItem>
        <MenuItem value={3}>{translate("days.wednesday")}</MenuItem>
        <MenuItem value={4}>{translate("days.thursday")}</MenuItem>
        <MenuItem value={5}>{translate("days.friday")}</MenuItem>
        <MenuItem value={6}>{translate("days.saturday")}</MenuItem>
      </Select>
    </FormControl>
  );
}
