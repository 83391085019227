import { HighchartsCustomProvider } from "../highcharts";
import { Duration } from "luxon";
import { renderToString } from "react-dom/server";

import {
  Chart,
  ColumnSeries,
  HighchartsChart,
  Title,
  Tooltip,
  XAxis,
  YAxis,
} from "react-jsx-highcharts";

interface BarchartDrilldownProps {
  title: string;
  // categories: string[];
  data: { name: string; y: number; drilldown: number }[];
  drilldown: {
    type: "column";
    borderColor: "none";
    id: number; // link -> this.data[x].drilldown
    data: [string, number][]; // [nome colonna, valore]
  }[];
}

/**
 * drilldown is applied based on this issue
 * @link https://github.com/whawker/react-jsx-highcharts/issues/29
 */

export const DurationDrilldown = ({
  title,
  data, // drilldown, // showLogarithmicToggle = true,
}: BarchartDrilldownProps & { showLogarithmicToggle?: boolean }) => {
  // const [checked, setChecked] = useState(showLogarithmicToggle);

  // const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   setChecked(event.target.checked);
  // };

  // for every instance in data, map it and rename the key to match the iteration of that specific name

  const countNames: { [key: string]: number } = {};

  // for every data, check if name is already in the countNames object and if it is, increment it by 1, otherwise set it to 1, then add the number to the name

  const newData = data.map((d) => {
    if (countNames[d.name]) {
      countNames[d.name] += 1;
    } else {
      countNames[d.name] = 1;
    }

    const returningData = {
      y: d.y,
      name: `${d.name} ${countNames[d.name]}`,
    };

    return returningData;
  });

  return (
    <>
      <HighchartsCustomProvider>
        <HighchartsChart
          accessibility={{ enabled: false }}
          chart={{
            type: "column",
          }}
          exporting={{
            enabled: true,
            buttons: {
              contextButton: {
                menuItems: ["downloadCSV", "downloadXLS"],
              },
            },
          }}
          alignTicks={false}
          // drilldown={{
          //   series: newDrilldown,
          //   activeDataLabelStyle: {
          //     color: "white",
          //   },
          //   activeAxisLabelStyle: {
          //     color: "white",
          //   },
          //   breadcrumbs: {
          //     buttonTheme: {
          //       style: {
          //         color: "white",
          //       },
          //     },
          //     style: {
          //       color: "white",
          //       fill: "white",
          //       style: {
          //         color: "white",
          //       },
          //     },
          //   },
          // }}
        >
          <Chart
            backgroundColor={"transparent"}
            type="column"
            zooming={{ type: "x" }}
            style={{
              color: "white",
            }}
            alignTicks={false}
          />
          <Title style={{ color: "white" }} align="left">
            {title}
          </Title>

          <Tooltip
            shared
            split={false}
            useHTML
            formatter={function () {
              if (this.x === undefined) return;

              const d = Duration.fromMillis(parseInt(this.y!.toString(), 10));
              const date = d.toFormat("hh:mm:ss");

              return renderToString(
                <div
                  style={{
                    width: "200px",
                    display: "flex",
                    flexDirection: "column",
                    gap: ".5rem",
                  }}
                >
                  {this.points
                    ? this.points.map((point) => (
                        <div key={point.key}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: "&bull;",
                            }}
                            style={{
                              color: point.color?.toString(),
                            }}
                          />
                          <b> {point.series.name}</b>:{this.points![0].key}{" "}
                          {date}
                          <br />
                        </div>
                      ))
                    : null}
                </div>
              );
            }}
          />
          <XAxis type="category"></XAxis>
          <YAxis
            type={"linear"}
            showEmpty
            dateTimeLabelFormats={{
              second: "%H:%M",
              minute: "%H:%M",
              hour: "%H:%M",
              day: "%H:%M",
              week: "%H:%M",
              month: "%H:%M",
              year: "%H:%M",
            }}
            labels={{
              style: { color: "white" },
              formatter: function () {
                if (typeof this.value === "number") {
                  return Duration.fromMillis(this.value).toFormat("hh:mm:ss");
                }
                return this.value;
              },
            }}
          >
            <ColumnSeries
              name="Batch"
              borderColor="none"
              data={newData}
              label={{
                style: {
                  color: "white",
                },
              }}
            />
          </YAxis>
        </HighchartsChart>
      </HighchartsCustomProvider>
    </>
  );
};
