import { create } from "zustand";

export type TimezoneType = "user" | "machine";

export type TimezoneStore = {
  timezoneType: TimezoneType;
  timezone: string;
  machineTimezone: string;
  setTimezoneType: (value: TimezoneType) => void;
  setTimezone: (timezone: string) => void;
  setMachineTimezone: (machineTimezone: string) => void;
};

export const timezoneStore = create<TimezoneStore>()((set) => ({
  timezoneType:
    (localStorage.getItem("timezoneType") as TimezoneType) || "machine",
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  machineTimezone: "",
  setTimezoneType(value: TimezoneType) {
    set(() => {
      localStorage.setItem("timezoneType", value.toString());
      return { timezoneType: value };
    });
  },
  setTimezone(newTimezone: string) {
    set(() => ({ timezone: newTimezone }));
  },
  setMachineTimezone(newMachineTimezone: string) {
    set((state) => {
      if (state.timezoneType === "machine") {
        state.timezone = newMachineTimezone;
      } else {
        state.machineTimezone =
          Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
      return { machineTimezone: newMachineTimezone };
    });
  },
}));
