import { z } from "zod";

export const ShiftsConfiguration = z.object({
  calendar_date: z.string(),
  shift_1_start: z.number().optional(),
  shift_1_end: z.number().optional(),
  shift_2_start: z.number().optional(),
  shift_2_end: z.number().optional(),
  shift_3_start: z.number().optional(),
  shift_3_end: z.number().optional(),
  shift_4_start: z.number().optional(),
  shift_4_end: z.number().optional(),
  is_locked: z.boolean(),
});

export type ShiftsConfiguration = z.infer<typeof ShiftsConfiguration>;
